<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="800px"
      v-if="resolutionScreen >= 500"
    >
      <!-- <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">Open Dialog</v-btn>
      </template>-->
      <v-card>
        <v-card-title
          >{{ $t("sharelinkfile.messageboxheader") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">link</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:550px">
          <v-layout row wrap class="pa-4">
            <v-flex xs12 lg12 v-if="dataAccountActive.type === 'Business'">
              <!-- {{filedata}} -->
              <div
                v-if="
                  role_level === 'True' || filedata.file_permission_2 !== '01'
                "
              >
                <v-layout row wrap class="pa-4 mb-n8 pt-0">
                  <!-- <v-flex xs4 lg4> -->
                    <!-- <v-switch
                      v-if="switch1 === true"
                      v-model="switch1"
                      :color="color.theme"
                      inset
                      :label="$t('sharelinkfile.statusYes')"
                      hide-details
                      @change="check_share()"
                    >
                    </v-switch>
                    <v-switch
                      v-if="switch1 === false"
                      v-model="switch1"
                      :color="color.theme"
                      inset
                      :label="$t('sharelinkfile.statusNo')"
                      hide-details
                      @change="check_share()"
                    ></v-switch> -->
                  <!-- </v-flex> -->
                  <!-- <v-spacer></v-spacer> -->
                  <!-- type sharelink -->
                  <v-flex xs3 lg3>
                    <!-- <v-select
                      v-model="typeshare"
                      :items="itemsshare"
                      :label="$t('sharelinkfile.statusaccess')"
                      dense
                      outlined
                      :disabled="!switch1"
                    ></v-select> -->
                    <v-select
                      v-model="typeshare"
                      :items="itemsshare"
                      :label="$t('sharelinkfile.statusaccess')"
                      dense
                      outlined
                    >
                    <!-- :disabled="!switch1" -->
                      <template v-slot:item="{ item }">
                        <div class="text-center">
                          <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                          <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                        </div>
                      </template>
                      <template v-slot:selection="{ item }">
                        <div class="text-center">
                          <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                          <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                        </div>
                      </template>
                    </v-select>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs3 lg6 class="text-right">
                    <span v-if="filedata.file_type === 'folder'" style="color: black; font-size: 18px">{{ $t("sharelinkfile.foldername") }}: {{ filedata.file_name }}</span>
                    <span v-else style="color: black; font-size: 18px">{{ $t("sharelinkfile.filename") }}: {{ filedata.file_name }}</span>
                  </v-flex>
                </v-layout>
                <br />
                <v-layout row wrap class="pa-4 pb-0 pt-0">
                  <v-flex xs12 sm12 lg10>
                    <v-text-field
                      id="textfield"
                      :color="color.theme"
                      v-model="link"
                      solo
                      name="input-7-4"
                      flat
                      label
                      outlined
                      readonly
                      rows="3"
                      dense
                      hide-details
                      class="mb-2 mr-2"
                    ></v-text-field>
                    <!-- :disabled="!disable_link" -->
                  </v-flex>
                  <v-flex xs12 sm12 lg1>
                    <v-btn
                      class="mt-0"
                      :disabled="!disable_link"
                      color="green"
                      :dark="disable_link"
                      @click="copytextarea()"
                    >
                      {{ $t("sharelinkfile.copy") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <br />
                <!-- qr code -->
                <v-layout row wrap class="pa-4 pb-0 pt-0" v-if="switch1 === true">
                  <!-- <v-flex xs10 lg3> -->
                    <v-switch
                      v-if="switch2 === true"
                      v-model="switch2"
                      :color="color.theme"
                      inset
                      :label="$t('sharelinkfile.qrcodeOn')"
                      hide-details
                      @change="check_share()"
                    >
                    </v-switch>
                    <v-switch
                      v-if="switch2 === false"
                      v-model="switch2"
                      :color="color.theme"
                      inset
                      :label="$t('sharelinkfile.qrcodeOff')"
                      hide-details
                      @change="check_share()"
                    ></v-switch>
                  <!-- </v-flex> -->
                </v-layout>
                <v-layout
                  v-if="switch2 === true"
                  row
                  wrap
                  class="pa-4 pb-0 pt-0"
                >
                  <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                    <img
                      v-if="resolutionScreen >= 400"
                      id="img"
                      :src="'data:image/jpeg;base64,' + qr_code"
                      width="30%"
                    />
                    <img
                      v-else
                      id="img"
                      :src="'data:image/jpeg;base64,' + qr_code"
                      width="60%"
                    />
                  </v-flex>
                  <v-flex xs12 lg12 mx-3 d-flex justify-center>
                    <v-btn
                      class="mt-0"
                      :disabled="!disable_link"
                      color="green"
                      :dark="disable_link"
                      @click="saveqrcode()"
                    >
                      {{ $t("sharelinkfile.saveqrcode") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <br />
                <v-layout
                  v-if="name_share_eng !== 'System' && switch1 === true"
                  justify-end
                  row
                  wrap
                >
                  <v-flex lg6 sm6 xs12 class="text-left pl-4">
                    <p style="color:red" v-if="typeshare === 'ทุกคนที่มีลิงก์'">
                      {{ $t("sharelinkfile.entersetplease") }}
                    </p>
                  </v-flex>
                  <v-flex lg6 sm6 xs11 class="text-right pr-2">
                    <p class="mb-0" v-if="$t('default') === 'th'">
                      {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                        name_share_th
                      }}
                    </p>
                    <p class="mb-0" v-else>
                      {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                        name_share_eng
                      }}
                    </p>
                  </v-flex>
                </v-layout>
                <br />
                <!-- กรอก email -->
                <v-layout class="mx-1" row wrap v-if="typeshare === 'จำกัด'">
                  <!-- switch1 === true &&  -->
                  <!-- ตั้งค่าการติดลายน้ำ -->
                  <v-flex lg12 class="ml-n6 mt-n6 mb-3">
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_watermark"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="pb-2"
                          align-start
                          style="cursor: default"
                        >
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              v-model="status_watermark"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11 style="line-height: 24px;">
                            {{ $t("sharelinkfile.setwatermark") }} <br/>
                            <span style="font-size: 12px; line-height: 20px; color: red;">
                              {{ $t("sharelinkfile.watermarlusefor") }}
                            </span>
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-flex>
                  <v-flex lg12 xs12 class="text-center">
                    <div
                      class="form-group"
                      v-for="(input, i) in inputs"
                      :key="i"
                    >
                      <v-layout lg12>
                        <v-flex lg6>
                          <v-text-field
                            outlined
                            dense
                            type="email"
                            v-model="input.email"
                            :label="$t('email')"
                            prepend-icon="mdi-email"
                            clearable
                            :rules="typeshare === 'จำกัด' ? emailErrors : ''"
                            :error-messages="typeshare === 'จำกัด' ? input.erroremail : ''"
                            @input="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                            @blur="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                          ></v-text-field>
                        </v-flex>
                        <v-flex lg4>
                          <v-select
                            hide-details
                            class="ml-1"
                            :items="values_permis"
                            :label="$t('sharelinkfile.permissionheader')"
                            v-model="input.permission"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex lg12 class="text-left mt-n4">
                          <v-card-title style="color:black; font-size: 15px;">
                            {{ $t("sharelinkfile.timeheader") }}
                          </v-card-title>
                        </v-flex>
                      </v-layout>
                      <v-layout lg12>
                        <v-flex lg5>
                          <v-dialog
                            persistent
                            v-model="input.modal"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                clearable
                                v-bind="attrs"
                                v-on="on"
                                v-model="input.picker"
                                :label="$t('sharelinkfile.date_set')"
                                readonly
                                prepend-icon="mdi-calendar"
                                outlined
                                dense
                                @input="$v.inputs.$each[i].picker.$touch()"
                                :error-messages="
                                  $v.inputs.$each[i].picker.$error
                                    ? 'โปรดระบุวันที่'
                                    : ''
                                "
                              >
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-model="input.picker"
                              :min="datenow"
                              @input="input.modal = false"
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                dark
                                color="error"
                                @click="input.modal = false"
                              >
                                {{ $t("confirm.closecomfirm") }}
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                        <v-flex lg3 xs12 class="ml-1">
                          <v-select
                            :label="$t('sharelinkfile.hours_set')"
                            outlined
                            dense
                            :items="hours"
                            v-model="input.hour"
                            prepend-icon="access_time"
                            clearable
                            @click:clear="$nextTick(() => (input.hour = ''))"
                            @input="$v.inputs.$each[i].hour.$touch()"
                            :error-messages="
                              $v.inputs.$each[i].hour.$error
                                ? 'โปรดระบุเวลา'
                                : ''
                            "
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg2 xs12>
                          <v-select
                            class="ml-1"
                            :label="$t('sharelinkfile.minute_set')"
                            outlined
                            dense
                            :items="minutes"
                            v-model="input.minute"
                            clearable
                            @click:clear="$nextTick(() => (input.minute = ''))"
                            @input="$v.inputs.$each[i].minute.$touch()"
                            :error-messages="
                              $v.inputs.$each[i].minute.$error
                                ? 'โปรดระบุเวลา'
                                : ''
                            "
                          >
                          </v-select>
                        </v-flex>
                        <v-flex lg2>
                          <v-icon
                            x-large
                            @click="remove(i)"
                            color="red"
                            dark
                            v-show="i || (!i && inputs.length > 1)"
                            >mdi-minus-circle</v-icon
                          >
                          <v-icon
                            x-large
                            @click="add(i)"
                            :color="color.theme"
                            dark
                            v-show="i == inputs.length - 1"
                            >mdi-plus-circle</v-icon
                          >
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
                <!-- <br /> -->
                <!-- v-if="switch1 === true && typeshare === 'ทุกคนที่มีลิงก์'" -->
                <div v-if="typeshare === 'ทุกคนที่มีลิงก์'">
                  <!-- switch1 === true &&  -->
                  <v-layout>
                    <h3 style="text-color:black;" class="mb-0">
                      {{ $t("sharelinkfile.setsharelinkfile") }}
                    </h3>
                  </v-layout>
                  <!-- ตั้งค่าการติดลายน้ำ -->
                  <v-layout row wrap>
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_watermark"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header
                          class="pb-2"
                          align-start
                          style="cursor: default"
                        >
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              v-model="status_watermark"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11 style="line-height: 24px;">
                            {{ $t("sharelinkfile.setwatermark") }} <br/>
                            <span style="font-size: 12px; line-height: 20px; color: red;">
                              {{ $t("sharelinkfile.watermarlusefor") }}
                            </span>
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                  <!-- กำหนดจำนวนครั้งใหม่ -->
                  <v-layout row wrap>
                    <!-- <v-flex xs1 lg1>
                    <v-switch v-model="limit_status" :color="color.theme" inset hide-details class="mt-1"></v-switch> </v-flex
                  > -->
                    <!-- <v-flex> -->
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_number"
                      :readonly="!limit_status"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pb-2" align-start>
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              @change="
                                checkbutton_number(limit_status, panel_number)
                              "
                              v-model="limit_status"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11>
                            {{ $t("sharelinkfile.limitheader") }}
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0 pb-2">
                          <v-layout row wrap>
                            <v-flex
                              xs2
                              lg1
                              sm1
                              class="mt-2 pl-3"
                              v-if="pleaselimitopeninput === true"
                            >
                              <v-icon large color="red"
                                >mdi-alert-octagon</v-icon
                              >
                            </v-flex>
                            <v-flex xs6 sm3 lg3>
                              <v-text-field
                                outlined
                                dense
                                class="ml-0 pl-3 mt-2 pr-2"
                                v-model="access_limit"
                                type="number"
                                :hint="$t('sharelinkfile.limitset')"
                                min="0"
                                persistent-hint
                                :disabled="!limit_status"
                              ></v-text-field>
                            </v-flex>
                            <v-flex xs9 sm5 lg5 class="mt-5">
                              <p style="color:red;" class="mb-0 pl-3">
                                {{ $t("sharelinkfile.numberopen_1") }} &nbsp;{{
                                  access_limit_count
                                }}/{{ access_limit }}&nbsp;
                                {{ $t("sharelinkfile.numberopen_2") }}
                              </p>
                            </v-flex>
                            <v-flex class="pt-4 pl-2" xs2 lg1>
                              <!-- <v-btn
                             v-if="limit_status === true"
                              @click="resetshare()"
                              color="green"
                              dark
                              absolute
                              x-small
                              fab
                              @mouseover="showReload = true"
                              @mouseleave="showReload = false"
                              ><v-icon small>mdi-reload</v-icon>
                              <v-badge
                                :value="showReload"
                                content="รีเซ็ตจำนวนครั้งที่ถูกเปิด"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn> -->
                              <v-btn
                                @mouseover="showReload = true"
                                @mouseleave="showReload = false"
                                v-if="limit_status === true"
                                @click="resetshare()"
                                color="green"
                                dark
                                absolute
                                small
                              >
                                {{ $t("sharelinkfile.reset") }}
                                <v-badge
                                  :value="showReload"
                                  :content="$t('sharelinkfile.resetword')"
                                  right
                                  transition="slide-y-transition"
                                ></v-badge>
                              </v-btn>
                            </v-flex>
                            <!-- <v-flex v-if="$t('default') === 'th'" xs4 lg4></v-flex>
                            <v-flex v-else xs3 lg3></v-flex> -->
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- </v-flex> -->
                  </v-layout>
                  <!-- กำหนดสิทธิ์ใหม่ -->
                  <v-layout row wrap>
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_permission"
                      :readonly="!permission_status"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pb-2">
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              @change="
                                checkbutton_permission(permission_status)
                              "
                              v-model="permission_status"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            >
                            </v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11>
                            {{ $t("sharelinkfile.permissionheader") }}
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0">
                          <v-flex class="pt-3" xs8 sm8 lg6>
                            <v-select
                              v-if="this.filedata['file_type'] === 'folder'"
                              hide-details
                              :items="
                                filedata.file_permission_2 === '01'
                                  ? values_folder_viewonly
                                  : values_folder
                              "
                              v-model="permission_value"
                              outlined
                              dense
                              return-object
                              :color="color.theme"
                              :item-color="color.theme"
                            >
                              <template v-slot:item="{ item }">{{
                                $t(item.text)
                              }}</template>
                              <template v-slot:selection="{ item }">{{
                                $t(item.text)
                              }}</template>
                            </v-select>
                            <v-select
                              v-else
                              hide-details
                              :items="
                                filedata.file_permission_2 === '01'
                                  ? values_file_viewonly
                                  : values_file
                              "
                              v-model="permission_value"
                              outlined
                              dense
                              return-object
                              :color="color.theme"
                              :item-color="color.theme"
                            >
                              <template v-slot:item="{ item }">{{
                                $t(item.text)
                              }}</template>
                              <template v-slot:selection="{ item }">{{
                                $t(item.text)
                              }}</template>
                            </v-select>
                          </v-flex>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                  <!-- กำหนดเวลาใหม่ -->
                  <v-layout row wrap>
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_time"
                      :readonly="!time_share_link_status"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pb-2">
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              @change="checkbutton_time(time_share_link_status)"
                              v-model="time_share_link_status"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            >
                            </v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11>
                            {{ $t("sharelinkfile.timeheader") }}
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0">
                          <v-layout row wrap>
                            <v-flex
                              xs2
                              lg1
                              sm1
                              class="pl-2 mt-3 mr-1"
                              v-if="pleasetimeinput === true"
                            >
                              <v-icon large color="red"
                                >mdi-alert-octagon</v-icon
                              >
                            </v-flex>
                            <v-flex class="mt-3 ml-2 mb-n4" lg5 sm3 xs9>
                              <v-dialog
                                persistent
                                v-model="modal2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model="picker"
                                    :label="$t('sharelinkfile.date_set')"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                  >
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="picker"
                                  :min="datenow"
                                  @input="modal2 = false"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click="modal2 = false"
                                  >
                                    {{ $t("confirm.closecomfirm") }}
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                            <v-flex
                              lg1
                              sm1
                              xs11
                              class="mt-3 pt-2 pl-4"
                              v-if="resolutionScreen >= 400"
                            >
                              <v-icon>access_time</v-icon>
                            </v-flex>
                            <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                              <v-select
                                :label="$t('sharelinkfile.hours_set')"
                                outlined
                                dense
                                :items="hours"
                                v-model="hour"
                                class="ml-2"
                              >
                              </v-select>
                            </v-flex>
                            <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                              <v-select
                                class="ml-2"
                                :label="$t('sharelinkfile.minute_set')"
                                outlined
                                dense
                                :items="minutes"
                                v-model="minute"
                              >
                              </v-select>
                            </v-flex>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                  <!-- กำหนดรหัสใหม่ -->
                  <v-layout row wrap>
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_password"
                      :readonly="!password_share_link_status"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pb-2">
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              @change="
                                checkbutton_password(password_share_link_status)
                              "
                              v-model="password_share_link_status"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            ></v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11>
                            {{ $t("sharelinkfile.passwordheader") }}
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0">
                          <v-layout>
                            <v-flex
                              xs2
                              sm1
                              lg1
                              class="mt-3"
                              v-if="pleasepasswordinput === true"
                            >
                              <v-icon large color="red"
                                >mdi-alert-octagon</v-icon
                              >
                            </v-flex>
                            <v-flex class="mt-3 " lg8 sm8 xs9>
                              <!-- กรอกรหัสผ่านลิงก์ -->
                              <v-text-field
                                autocomplete="off"
                                :label="$t('checkpassword.inputpassword')"
                                solo
                                hide-details
                                v-model="password_link"
                                outlined
                                dense
                                @click:append="showpassword = !showpassword"
                                prepend-icon="mdi-lock"
                                :disabled="ownerpassword"
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                </div>
              </div>
              <div v-else-if="filedata.file_permission_2 === '01'">
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkfile.cannotshare')"
                  hide-details
                  readonly
                ></v-switch>
              </div>
              <!-- <v-layout> -->
              <!-- <v-flex xs6 lg6>
                    <v-switch
                      v-if="permission_status === true"
                      
                      v-model="permission_status"
                      :color="color.theme"
                      inset
                      label="เปิดกำหนดสิทธิ์แชร์ลิงก์"
                      hide-details
                      class="mt-1"
                    >
                    </v-switch>  
                    <v-switch
                     v-if="permission_status === false"
                      v-model="permission_status"
                      :color="color.theme"
                      inset
                      label="ปิดกำหนดสิทธิ์แชร์ลิงก์"
                      hide-details
                      class="mt-1"
                    >
                    </v-switch>                 
                  </v-flex> -->
              <!-- </v-layout> -->
              <!-- <div v-else>
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkfile.statusNo')"
                  hide-details
                ></v-switch>
              </div> -->
            </v-flex>
            <!-- Citizen -->
            <v-flex xs12 lg12 v-else-if="dataAccountActive.type === 'Citizen'">
              <!-- <div v-if="switch1 === true"> -->
              <!-- <div> -->
              <v-layout row wrap class="pa-4 mb-n8 pt-0">
                <!-- <v-flex xs9 lg9> -->
                  <!-- <v-switch
                    v-if="switch1 === true"
                    v-model="switch1"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkfile.statusYes')"
                    hide-details
                    @change="check_share()"
                  >
                  </v-switch>
                  <v-switch
                    v-if="switch1 === false"
                    v-model="switch1"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkfile.statusNo')"
                    hide-details
                    @change="check_share()"
                  ></v-switch> -->
                  <!-- <v-spacer></v-spacer> -->
                <!-- </v-flex> -->
                <!-- type sharelink -->
                <v-flex xs3 lg3>
                  <!-- <v-select
                    v-model="typeshare"
                    :items="itemsshare"
                    :label="$t('sharelinkfile.statusaccess')"
                    dense
                    outlined
                    :disabled="!switch1"
                  ></v-select> -->
                  <v-select
                    v-model="typeshare"
                    :items="itemsshare"
                    :label="$t('sharelinkfile.statusaccess')"
                    dense
                    outlined
                  >
                  <!-- :disabled="!switch1" -->
                    <template v-slot:item="{ item }">
                      <div class="text-center">
                        <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                        <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div class="text-center">
                        <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                        <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                      </div>
                    </template>
                  </v-select>
                </v-flex>
                <v-spacer></v-spacer>
                <v-flex xs3 lg6 class="text-right">
                  <span v-if="filedata.file_type === 'folder'" style="color: black; font-size: 18px">{{ $t("sharelinkfile.foldername") }}: {{ filedata.file_name }}</span>
                  <span v-else style="color: black; font-size: 18px">{{ $t("sharelinkfile.filename") }}: {{ filedata.file_name }}</span>
                </v-flex>
              </v-layout>
              <br />
              <v-layout row wrap class="pa-4 pb-0 pt-0">
                <v-flex xs12 sm12 lg10>
                  <v-text-field
                    id="textfield"
                    :color="color.theme"
                    v-model="link"
                    solo
                    name="input-7-4"
                    flat
                    label
                    outlined
                    rows="3"
                    dense
                    hide-details
                    readonly
                    class="mb-2 mr-2"
                  ></v-text-field>
                  <!-- :disabled="!disable_link" -->
                </v-flex>
                <v-flex xs12 sm12 lg1>
                  <v-btn
                    class="mt-0"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="copytextarea()"
                  >
                    <!-- <v-icon>
                        file_copy
                      </v-icon> -->
                    {{ $t("sharelinkfile.copy") }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
                         <!-- qr code -->
              <v-layout row wrap class="pa-4 pb-0 pt-0" v-if="switch1 === true">
                <!-- <v-flex xs10 lg10> -->
                  <v-switch
                    v-if="switch2 === true"
                    v-model="switch2"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkfile.qrcodeOn')"
                    hide-details
                    @change="check_share()"
                  >
                  </v-switch>
                  <v-switch
                    v-if="switch2 === false"
                    v-model="switch2"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkfile.qrcodeOff')"
                    hide-details
                    @change="check_share()"
                  ></v-switch>
                <!-- </v-flex> -->
                <!-- type sharelink -->
                <!-- <v-flex xs3 lg3>
                    <v-select
                      v-model="typeshare"
                      :items="itemsshare"
                      label="การเข้าถึงทั่วไป"
                      dense
                      outlined
                      :disabled="!switch1"
                    ></v-select>
                  </v-flex> -->
              </v-layout>
              <v-layout v-if="switch2 === true" row wrap class="pa-4 pb-0 pt-0">
                <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                  <img
                    v-if="resolutionScreen >= 400"
                    id="img"
                    :src="'data:image/jpeg;base64,' + qr_code"
                    width="30%"
                  />
                  <img
                    v-else
                    id="img"
                    :src="'data:image/jpeg;base64,' + qr_code"
                    width="60%"
                  />
                </v-flex>
                <v-flex xs12 lg12 mx-3 d-flex justify-center>
                  <v-btn
                    class="mt-0"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="saveqrcode()"
                  >
                    {{ $t("sharelinkfile.saveqrcode") }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
              <v-layout
                v-if="name_share_eng !== 'System' && switch1 === true"
                justify-end
                row
                wrap
              >
                <v-flex lg6 sm6 xs12 class="text-left pl-4">
                  <p style="color:red">
                    {{ $t("sharelinkfile.entersetplease") }}
                  </p>
                </v-flex>
                <v-flex lg6 sm6 xs11 class="text-right pr-2">
                  <p class="mb-0" v-if="$t('default') === 'th'">
                    {{ $t("sharelinkfile.shareby") }}:&nbsp;{{ name_share_th }}
                  </p>
                  <p class="mb-0" v-else>
                    {{ $t("sharelinkfile.shareby") }}:&nbsp;{{ name_share_eng }}
                  </p>
                </v-flex>
              </v-layout>
              <br />
              <!-- กรอก email -->
              <v-layout class="mx-1" row wrap v-if="typeshare === 'จำกัด'">
                <!-- switch1 === true &&  -->
                <!-- ตั้งค่าการติดลายน้ำ -->
                <v-flex lg12 class="ml-n6 mt-n8 mb-3">
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_watermark"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="pb-2"
                        align-start
                        style="cursor: default"
                      >
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            v-model="status_watermark"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11 style="line-height: 24px;">
                          {{ $t("sharelinkfile.setwatermark") }} <br/>
                          <span style="font-size: 12px; line-height: 20px; color: red;">
                            {{ $t("sharelinkfile.watermarlusefor") }}
                          </span>
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-flex>
                <v-flex lg12 xs12 class="text-center">
                  <div class="form-group" v-for="(input, i) in inputs" :key="i">
                    <v-layout lg12>
                      <v-flex lg6>
                        <v-text-field
                          outlined
                          dense
                          type="email"
                          v-model="input.email"
                          :label="$t('email')"
                          prepend-icon="mdi-email"
                          clearable
                          :rules="typeshare === 'จำกัด' ? emailErrors : ''"
                          :error-messages="typeshare === 'จำกัด' ? input.erroremail : ''"
                          @input="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                          @blur="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                        ></v-text-field>
                      </v-flex>
                      <v-flex lg4>
                        <v-select
                          hide-details
                          class="ml-1"
                          :items="values_permis"
                          :label="$t('sharelinkfile.permissionheader')"
                          v-model="input.permission"
                          outlined
                          dense
                          return-object
                          :color="color.theme"
                          :item-color="color.theme"
                        >
                          <template v-slot:item="{ item }">{{
                            $t(item.text)
                          }}</template>
                          <template v-slot:selection="{ item }">{{
                            $t(item.text)
                          }}</template>
                        </v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex lg12 class="text-left mt-n4">
                        <v-card-title style="color:black; font-size: 15px;">
                          {{ $t("sharelinkfile.timeheader") }}
                        </v-card-title>
                      </v-flex>
                    </v-layout>
                    <v-layout lg12>
                      <v-flex lg5>
                        <v-dialog
                          persistent
                          v-model="input.modal"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              clearable
                              v-bind="attrs"
                              v-on="on"
                              v-model="input.picker"
                              :label="$t('sharelinkfile.date_set')"
                              readonly
                              prepend-icon="mdi-calendar"
                              outlined
                              dense
                              @input="$v.inputs.$each[i].picker.$touch()"
                              :error-messages="
                                $v.inputs.$each[i].picker.$error
                                  ? 'โปรดระบุวันที่'
                                  : ''
                              "
                            >
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="input.picker"
                            :min="datenow"
                            @input="input.modal = false"
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              dark
                              color="error"
                              @click="input.modal = false"
                            >
                              {{ $t("confirm.closecomfirm") }}
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-flex>
                      <v-flex lg3 xs12 class="ml-1">
                        <v-select
                          :label="$t('sharelinkfile.hours_set')"
                          outlined
                          dense
                          :items="hours"
                          v-model="input.hour"
                          prepend-icon="access_time"
                          clearable
                          @click:clear="$nextTick(() => (input.hour = ''))"
                          @input="$v.inputs.$each[i].hour.$touch()"
                          :error-messages="
                            $v.inputs.$each[i].hour.$error ? 'โปรดระบุเวลา' : ''
                          "
                        >
                        </v-select>
                      </v-flex>
                      <v-flex lg2 xs12>
                        <v-select
                          class="ml-1"
                          :label="$t('sharelinkfile.minute_set')"
                          outlined
                          dense
                          :items="minutes"
                          v-model="input.minute"
                          clearable
                          @click:clear="$nextTick(() => (input.minute = ''))"
                          @input="$v.inputs.$each[i].minute.$touch()"
                          :error-messages="
                            $v.inputs.$each[i].minute.$error
                              ? 'โปรดระบุเวลา'
                              : ''
                          "
                        >
                        </v-select>
                      </v-flex>
                      <v-flex lg2>
                        <v-icon
                          x-large
                          @click="remove(i)"
                          color="red"
                          dark
                          v-show="i || (!i && inputs.length > 1)"
                          >mdi-minus-circle</v-icon
                        >
                        <v-icon
                          x-large
                          @click="add(i)"
                          :color="color.theme"
                          dark
                          v-show="i == inputs.length - 1"
                          >mdi-plus-circle</v-icon
                        >
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
              <br />
              <div v-if="typeshare === 'ทุกคนที่มีลิงก์'">
                <!-- switch1 === true &&  -->
                <v-layout>
                  <h3 style="text-color:black;" class="mb-0">
                    {{ $t("sharelinkfile.setsharelinkfile") }}
                  </h3>
                </v-layout>
                <!-- ตั้งค่าการติดลายน้ำ -->
                <v-layout row wrap>
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_watermark"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="pb-2"
                        align-start
                        style="cursor: default"
                      >
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            v-model="status_watermark"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11 style="line-height: 24px;">
                          {{ $t("sharelinkfile.setwatermark") }} <br/>
                          <span style="font-size: 12px; line-height: 20px; color: red;">
                            {{ $t("sharelinkfile.watermarlusefor") }}
                          </span>
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
                <!-- กำหนดจำนวนครั้งใหม่ -->
                <v-layout row wrap>
                  <!-- <v-flex xs1 lg1>
                    <v-switch v-model="limit_status" :color="color.theme" inset hide-details class="mt-1"></v-switch> </v-flex
                  > -->
                  <!-- <v-flex> -->
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_number"
                    :readonly="!limit_status"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pb-2" align-start>
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            @change="
                              checkbutton_number(limit_status, panel_number)
                            "
                            v-model="limit_status"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11>
                          {{ $t("sharelinkfile.limitheader") }}
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pa-0 pb-2">
                        <v-layout row wrap>
                          <v-flex
                            xs2
                            lg1
                            sm1
                            class="mt-2 pl-3"
                            v-if="pleaselimitopeninput === true"
                          >
                            <v-icon large color="red">mdi-alert-octagon</v-icon>
                          </v-flex>
                          <v-flex xs6 sm3 lg3>
                            <v-text-field
                              outlined
                              dense
                              class="ml-0 pl-3 mt-2 pr-2"
                              v-model="access_limit"
                              type="number"
                              :hint="$t('sharelinkfile.limitset')"
                              min="0"
                              persistent-hint
                              :disabled="!limit_status"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs9 sm5 lg5 class="mt-5">
                            <p style="color:red;" class="mb-0 pl-3">
                              {{ $t("sharelinkfile.numberopen_1") }} &nbsp;{{
                                access_limit_count
                              }}/{{ access_limit }}&nbsp;
                              {{ $t("sharelinkfile.numberopen_2") }}
                            </p>
                          </v-flex>
                          <v-flex class="pt-4 pl-2" xs2 lg1>
                            <!-- <v-btn
                             v-if="limit_status === true"
                              @click="resetshare()"
                              color="green"
                              dark
                              absolute
                              x-small
                              fab
                              @mouseover="showReload = true"
                              @mouseleave="showReload = false"
                              ><v-icon small>mdi-reload</v-icon>
                              <v-badge
                                :value="showReload"
                                content="รีเซ็ตจำนวนครั้งที่ถูกเปิด"
                                left
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn> -->
                            <v-btn
                              @mouseover="showReload = true"
                              @mouseleave="showReload = false"
                              v-if="limit_status === true"
                              @click="resetshare()"
                              color="green"
                              dark
                              absolute
                              small
                            >
                              {{ $t("sharelinkfile.reset") }}
                              <v-badge
                                :value="showReload"
                                :content="$t('sharelinkfile.resetword')"
                                right
                                transition="slide-y-transition"
                              ></v-badge>
                            </v-btn>
                          </v-flex>
                          <!-- <v-flex v-if="$t('default') === 'th'" xs4 lg4></v-flex>
                            <v-flex v-else xs3 lg3></v-flex> -->
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <!-- </v-flex> -->
                </v-layout>
                <!-- กำหนดสิทธิ์ใหม่ -->
                <v-layout row wrap>
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_permission"
                    :readonly="!permission_status"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pb-2">
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            @change="checkbutton_permission(permission_status)"
                            v-model="permission_status"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          >
                          </v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11>
                          {{ $t("sharelinkfile.permissionheader") }}
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pb-0">
                        <v-flex class="pt-3" xs8 sm8 lg6>
                          <v-select
                            v-if="this.filedata['file_type'] === 'folder'"
                            hide-details
                            :items="
                              filedata.file_permission_2 === '01'
                                ? values_folder_viewonly
                                : values_folder
                            "
                            v-model="permission_value"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                          <v-select
                            v-else
                            hide-details
                            :items="
                              filedata.file_permission_2 === '01'
                                ? values_file_viewonly
                                : values_file
                            "
                            v-model="permission_value"
                            outlined
                            dense
                            return-object
                            :color="color.theme"
                            :item-color="color.theme"
                          >
                            <template v-slot:item="{ item }">{{
                              $t(item.text)
                            }}</template>
                            <template v-slot:selection="{ item }">{{
                              $t(item.text)
                            }}</template>
                          </v-select>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
                <!-- กำหนดเวลาใหม่ -->
                <v-layout row wrap>
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_time"
                    :readonly="!time_share_link_status"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pb-2">
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            @change="checkbutton_time(time_share_link_status)"
                            v-model="time_share_link_status"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          >
                          </v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11>
                          {{ $t("sharelinkfile.timeheader") }}
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pb-0">
                        <v-layout row wrap>
                          <v-flex
                            xs2
                            lg1
                            sm1
                            class="pl-2 mt-3 mr-1"
                            v-if="pleasetimeinput === true"
                          >
                            <v-icon large color="red">mdi-alert-octagon</v-icon>
                          </v-flex>
                          <v-flex class="mt-3 ml-2 mb-n4" lg5 sm3 xs9>
                            <v-dialog
                              persistent
                              v-model="modal2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="picker"
                                  :label="$t('sharelinkfile.date_set')"
                                  readonly
                                  prepend-icon="mdi-calendar"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="picker"
                                :min="datenow"
                                @input="modal2 = false"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  dark
                                  color="error"
                                  @click="modal2 = false"
                                >
                                  {{ $t("confirm.closecomfirm") }}
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex
                            lg1
                            sm1
                            xs11
                            class="mt-3 pt-2 pl-4"
                            v-if="resolutionScreen >= 400"
                          >
                            <v-icon>access_time</v-icon>
                          </v-flex>
                          <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                            <v-select
                              :label="$t('sharelinkfile.hours_set')"
                              outlined
                              dense
                              :items="hours"
                              v-model="hour"
                              class="ml-2"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                            <v-select
                              class="ml-2"
                              :label="$t('sharelinkfile.minute_set')"
                              outlined
                              dense
                              :items="minutes"
                              v-model="minute"
                            >
                            </v-select>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
                <!-- กำหนดรหัสใหม่ -->
                <v-layout row wrap>
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_password"
                    :readonly="!password_share_link_status"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pb-2">
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            @change="
                              checkbutton_password(password_share_link_status)
                            "
                            v-model="password_share_link_status"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11>
                          {{ $t("sharelinkfile.passwordheader") }}
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pb-0">
                        <v-layout>
                          <v-flex
                            xs2
                            sm1
                            lg1
                            class="mt-3"
                            v-if="pleasepasswordinput === true"
                          >
                            <v-icon large color="red">mdi-alert-octagon</v-icon>
                          </v-flex>
                          <v-flex class="mt-3" lg8 sm8 xs9>
                            <!-- กรอกรหัสผ่านลิงก์ -->
                            <v-text-field
                              autocomplete="off"
                              :label="$t('checkpassword.inputpassword')"
                              solo
                              hide-details
                              v-model="password_link"
                              outlined
                              dense
                              @click:append="showpassword = !showpassword"
                              prepend-icon="mdi-lock"
                              :disabled="ownerpassword"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
              </div>

              <!-- </div> -->
              <!-- <div v-else>
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkfile.statusNo')"
                  hide-details
                ></v-switch>
              </div> -->
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="$emit('close'), cleardata(), $emit('openDrag'), $emit('opendragselect'), checkreload === true ? $emit('reload'): ''"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            v-if="switch1 === false"
            :loading="btnsetload"
            :color="color.theme"
            class="white--text"
            @click="opensharelink()"
            :disabled="typeshare === 'จำกัด' ? checkemail : false || loading"
            >{{ $t("sharelinkfile.statusYes") }}</v-btn
          >
          <v-btn
            v-else
            :loading="btnsetload"
            :color="color.theme"
            class="white--text"
            @click="closesharelink()"
            :disabled="loading"
            >{{ $t("sharelinkfile.statusNo") }}</v-btn
          >
          <v-btn
            v-if="switch1 === true"
            :loading="btnsetload"
            :color="color.theme"
            class="white--text"
            :disabled="typeshare === 'จำกัด' ? checkemail : false || loading"
            @click="updatesharelink()"
            >{{ $t("sharelinkfile.setting") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("sharelinkfile.messageboxheader")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="$emit('close'), cleardata(), $emit('openDrag'), $emit('opendragselect')"
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 400px;">
          <v-layout row wrap class="pa-2">
            <v-flex xs12 lg12 v-if="dataAccountActive.type === 'Business'">
              <div
                v-if="
                  role_level === 'True' || filedata.file_permission_2 !== '01'
                "
              >
                <!-- <v-switch
                  v-if="switch1 === true"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkfile.statusYes')"
                  hide-details
                  @change="check_share()"
                  class="mb-4"
                >
                </v-switch>
                <v-switch
                  v-if="switch1 === false"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkfile.statusNo')"
                  hide-details
                  @change="check_share()"
                  class="mb-4"
                ></v-switch> -->
                <span v-if="filedata.file_type === 'folder'" style="color: black; font-size: 14px">{{ $t("sharelinkfile.foldername") }}: {{ filedata.file_name }}</span>
                <span v-else style="color: black; font-size: 14px">{{ $t("sharelinkfile.filename") }}: {{ filedata.file_name }}</span>
                <br />
                <br />
                <span :style="titleText">{{
                  $t("sharelinkfile.statusaccess")
                }}</span>
                <v-select
                  v-model="typeshare"
                  :items="itemsshare"
                  dense
                  outlined
                  class="mt-1"
                >
                <!-- :disabled="!switch1" -->
                  <template v-slot:item="{ item }">
                    <div class="text-center">
                      <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                      <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                    </div>
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-center">
                      <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                      <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                    </div>
                  </template>
                </v-select>
                <v-list-item class="mt-n2">
                  <v-text-field
                    id="textfield"
                    :color="color.theme"
                    v-model="link"
                    solo
                    name="input-7-4"
                    flat
                    label
                    outlined
                    rows="3"
                    dense
                    hide-details
                    readonly
                    class="mb-2 ml-n4"
                    style="width:20px;"
                  ></v-text-field>
                  <!-- :disabled="!disable_link" -->
                  <v-btn
                    class="mt-n2 mr-n4 ml-1"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="copytextarea()"
                  >
                    <v-icon>mdi-link mdi-rotate-135</v-icon>
                    {{ $t("sharelinkfile.copy") }}
                  </v-btn>
                </v-list-item>
                <br />
                <v-layout row wrap>
                  <!-- qr code -->
                  <v-expansion-panels
                    v-model="switch2"
                    class="px-2 rounded-xl"
                    active-class="rounded-xl"
                    v-if="switch1 === true"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="my-n2 rounded-xl"
                      >
                        <span
                          ><v-icon small>mdi-qrcode-scan</v-icon
                          ><span :style="titleText" class="ml-2">{{
                            $t("sharelinkfile.showqrcode")
                          }}</span></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="py-2 rounded-xl" elevation="0">
                        <v-flex
                          xs12
                          lg12
                          d-flex
                          justify-center
                          class="mt-2 mb-2"
                        >
                          <img
                            v-if="resolutionScreen >= 400"
                            id="img"
                            :src="'data:image/jpeg;base64,' + qr_code"
                            width="30%"
                          />
                          <img
                            v-else
                            id="img"
                            :src="'data:image/jpeg;base64,' + qr_code"
                            width="60%"
                          />
                        </v-flex>
                        <v-flex xs12 lg12 mx-3 d-flex justify-center>
                          <v-btn
                            class="mt-0"
                            :disabled="!disable_link"
                            color="green"
                            :dark="disable_link"
                            @click="saveqrcode()"
                            outlined
                          >
                            <v-icon small class="mr-1">mdi-qrcode-scan</v-icon
                            >{{ $t("sharelinkfile.saveqrcode") }}
                          </v-btn>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <br />
                  <v-expansion-panels
                    class="mt-8 px-2 rounded-xl"
                    active-class="rounded-xl"
                  >
                  <!-- v-if="switch1 === true" -->
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        class="my-n2 rounded-xl"
                      >
                        <span
                          ><v-icon small>mdi-cogs</v-icon
                          ><span class="ml-2" :style="titleText">{{
                            $t("sharelinkfile.setsharelinkfile")
                          }}</span></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="py-2 mt-n2 rounded-xl"
                        elevation="0"
                      >
                        <v-layout
                          v-if="name_share_eng !== 'System' && switch1 === true"
                          justify-end
                          row
                          wrap
                        >
                          <v-flex xs12 class="text-right mt-3 mb-n1">
                            <p class="mb-0" v-if="$t('default') === 'th'">
                              {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                                name_share_th
                              }}
                            </p>
                            <p class="mb-0" v-else>
                              {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                                name_share_eng
                              }}
                            </p>
                          </v-flex>
                        </v-layout>
                        <!-- กรอก email -->
                        <!-- switch1 === true && -->
                        <div v-if="typeshare === 'จำกัด'">
                          <!-- ตั้งค่าการติดลายน้ำ -->
                          <v-layout row wrap class="px-n4">
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_watermark"
                              style="cursor: default"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="pb-2"
                                  align-start
                                >
                                  <v-flex xs1>
                                    <v-switch
                                      v-model="status_watermark"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1 ml-n4"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex class="ml-6" xs11 :style="titleText">
                                    {{ $t("sharelinkfile.setwatermark") }} <br />
                                    <span xs12 style="font-size: 12px; line-height: 20px; color: red;">
                                      {{ $t("sharelinkfile.watermarlusefor") }}
                                    </span>
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <v-layout row wrap class="mx-4 mt-2">
                            <v-flex lg12 xs12 class="text-center">
                              <div
                                class="form-group"
                                v-for="(input, i) in inputs"
                                :key="i"
                              >
                                <v-layout row wrap class="mb-3">
                                  <v-flex xs12 class="mb-n3">
                                    <v-text-field
                                      outlined
                                      dense
                                      type="email"
                                      v-model="input.email"
                                      :label="$t('email')"
                                      prepend-icon="mdi-email"
                                      clearable
                                      :rules="typeshare === 'จำกัด' ? emailErrors : ''"
                                      :error-messages="typeshare === 'จำกัด' ? input.erroremail : ''"
                                      @input="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                                      @blur="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex xs12>
                                    <v-select
                                      hide-details
                                      class="ml-8"
                                      :items="values_permis"
                                      :label="
                                        $t('sharelinkfile.permissionheader')
                                      "
                                      v-model="input.permission"
                                      outlined
                                      dense
                                      return-object
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    >
                                      <template v-slot:item="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                      <template v-slot:selection="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                    </v-select>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap >
                                  <v-flex lg12 class="text-left mt-n4 ml-n2">
                                    <v-card-title style="color:black; font-size: 14px;">
                                      {{ $t("sharelinkfile.timeheader") }}
                                    </v-card-title>
                                  </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                  <v-flex xs12 class="mb-n3">
                                    <v-dialog
                                      persistent
                                      v-model="input.modal"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          clearable
                                          v-bind="attrs"
                                          v-on="on"
                                          v-model="input.picker"
                                          :label="$t('sharelinkfile.date_set')"
                                          readonly
                                          prepend-icon="mdi-calendar"
                                          outlined
                                          dense
                                          @input="
                                            $v.inputs.$each[i].picker.$touch()
                                          "
                                          :error-messages="
                                            $v.inputs.$each[i].picker.$error
                                              ? 'โปรดระบุวันที่'
                                              : ''
                                          "
                                        >
                                        </v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="input.picker"
                                        :min="datenow"
                                        @input="input.modal = false"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click="input.modal = false"
                                        >
                                          {{ $t("confirm.closecomfirm") }}
                                        </v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <v-flex xs12 class="mb-n3">
                                    <v-select
                                      :label="$t('sharelinkfile.hours_set')"
                                      outlined
                                      dense
                                      :items="hours"
                                      v-model="input.hour"
                                      prepend-icon="access_time"
                                      clearable
                                      @click:clear="
                                        $nextTick(() => (input.hour = ''))
                                      "
                                      @input="$v.inputs.$each[i].hour.$touch()"
                                      :error-messages="
                                        $v.inputs.$each[i].hour.$error
                                          ? 'โปรดระบุเวลา'
                                          : ''
                                      "
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex xs12 class="mb-n3">
                                    <v-select
                                      class="ml-1"
                                      prepend-icon="access_time"
                                      :label="$t('sharelinkfile.minute_set')"
                                      outlined
                                      dense
                                      :items="minutes"
                                      v-model="input.minute"
                                      clearable
                                      @click:clear="
                                        $nextTick(() => (input.minute = ''))
                                      "
                                      @input="
                                        $v.inputs.$each[i].minute.$touch()
                                      "
                                      :error-messages="
                                        $v.inputs.$each[i].minute.$error
                                          ? 'โปรดระบุเวลา'
                                          : ''
                                      "
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex xs12 class="mb-4">
                                    <v-icon
                                      x-large
                                      @click="remove(i)"
                                      color="red"
                                      dark
                                      v-show="i || (!i && inputs.length > 1)"
                                      >mdi-minus-circle</v-icon
                                    >
                                    <v-icon
                                      x-large
                                      @click="add(i)"
                                      :color="color.theme"
                                      dark
                                      v-show="i == inputs.length - 1"
                                      >mdi-plus-circle</v-icon
                                    >
                                  </v-flex>
                                </v-layout>
                              </div>
                            </v-flex>
                          </v-layout>
                        </div>
                        <div
                          v-if="
                            typeshare === 'ทุกคนที่มีลิงก์'
                          "
                        >
                        <!-- switch1 === true && -->
                          <!-- ตั้งค่าการติดลายน้ำ -->
                          <v-layout row wrap class="px-n4">
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_watermark"
                              style="cursor: default"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="pb-2"
                                  align-start
                                >
                                  <v-flex xs3>
                                    <v-switch
                                      v-model="status_watermark"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs11 :style="titleText">
                                    {{ $t("sharelinkfile.setwatermark") }} <br/>
                                    <span xs12 style="font-size: 12px; line-height: 20px; color: red;">
                                      {{ $t("sharelinkfile.watermarlusefor") }}
                                    </span>
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <!-- กำหนดจำนวนครั้งใหม่ -->
                          <v-layout row wrap class="px-n4">
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_number"
                              :readonly="!limit_status"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header
                                  class="pb-2"
                                  align-start
                                >
                                  <v-flex xs3>
                                    <v-switch
                                      @change="
                                        checkbutton_number(
                                          limit_status,
                                          panel_number
                                        )
                                      "
                                      v-model="limit_status"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs11 :style="titleText">
                                    {{ $t("sharelinkfile.limitheader") }}
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pa-0 pb-2">
                                  <v-layout row wrap>
                                    <v-flex xs9 class="ml-3">
                                      <v-text-field
                                        outlined
                                        dense
                                        class="ml-0 pl-3 mt-2 pr-2"
                                        v-model="access_limit"
                                        type="number"
                                        :hint="$t('sharelinkfile.limitset')"
                                        min="0"
                                        persistent-hint
                                        :disabled="!limit_status"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-2 pl-1"
                                      v-if="pleaselimitopeninput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                    <v-flex xs9 sm5 lg5 class="mt-5">
                                      <p style="color:red;" class="mb-0 pl-3">
                                        {{
                                          $t("sharelinkfile.numberopen_1")
                                        }}
                                        &nbsp;{{ access_limit_count }}/{{
                                          access_limit
                                        }}&nbsp;
                                        {{ $t("sharelinkfile.numberopen_2") }}
                                      </p>
                                    </v-flex>
                                    <v-flex class="pt-4 pl-2" xs2 lg1>
                                      <v-btn
                                        @mouseover="showReload = true"
                                        @mouseleave="showReload = false"
                                        v-if="limit_status === true"
                                        @click="resetshare()"
                                        color="green"
                                        dark
                                        absolute
                                        small
                                      >
                                        {{ $t("sharelinkfile.reset") }}
                                        <v-badge
                                          :value="showReload"
                                          :content="
                                            $t('sharelinkfile.resetword')
                                          "
                                          right
                                          transition="slide-y-transition"
                                        ></v-badge>
                                      </v-btn>
                                    </v-flex>
                                  </v-layout>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <!-- กำหนดสิทธิ์ใหม่ -->
                          <v-layout row wrap class="px-n4">
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_permission"
                              :readonly="!permission_status"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header class="pb-2">
                                  <v-flex xs3>
                                    <v-switch
                                      @change="
                                        checkbutton_permission(
                                          permission_status
                                        )
                                      "
                                      v-model="permission_status"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    >
                                    </v-switch>
                                  </v-flex>
                                  <v-flex xs11>
                                    {{ $t("sharelinkfile.permissionheader") }}
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pb-0">
                                  <v-flex class="pt-3 ml-3" xs9>
                                    <v-select
                                      v-if="
                                        this.filedata['file_type'] === 'folder'
                                      "
                                      hide-details
                                      :items="
                                        filedata.file_permission_2 === '01'
                                          ? values_folder_viewonly
                                          : values_folder
                                      "
                                      v-model="permission_value"
                                      outlined
                                      dense
                                      return-object
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    >
                                      <template v-slot:item="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                      <template v-slot:selection="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                    </v-select>
                                    <v-select
                                      v-else
                                      hide-details
                                      :items="
                                        filedata.file_permission_2 === '01'
                                          ? values_file_viewonly
                                          : values_file
                                      "
                                      v-model="permission_value"
                                      outlined
                                      dense
                                      return-object
                                      :color="color.theme"
                                      :item-color="color.theme"
                                    >
                                      <template v-slot:item="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                      <template v-slot:selection="{ item }">{{
                                        $t(item.text)
                                      }}</template>
                                    </v-select>
                                  </v-flex>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <!-- กำหนดเวลาใหม่ -->
                          <v-layout row wrap>
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_time"
                              :readonly="!time_share_link_status"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header class="pb-2">
                                  <v-flex xs3 class="px-n4">
                                    <v-switch
                                      @change="
                                        checkbutton_time(time_share_link_status)
                                      "
                                      v-model="time_share_link_status"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    >
                                    </v-switch>
                                  </v-flex>
                                  <v-flex xs11>
                                    {{ $t("sharelinkfile.timeheader") }}
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pb-0">
                                  <v-layout row wrap>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-dialog
                                        persistent
                                        v-model="modal2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="picker"
                                            :label="
                                              $t('sharelinkfile.date_set')
                                            "
                                            readonly
                                            prepend-icon="mdi-calendar"
                                            outlined
                                            dense
                                          >
                                          </v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="picker"
                                          :min="datenow"
                                          @input="modal2 = false"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click="modal2 = false"
                                          >
                                            {{ $t("confirm.closecomfirm") }}
                                          </v-btn>
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-select
                                        :label="$t('sharelinkfile.hours_set')"
                                        outlined
                                        dense
                                        :items="hours"
                                        v-model="hour"
                                        prepend-icon="access_time"
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-select
                                        prepend-icon="access_time"
                                        :label="$t('sharelinkfile.minute_set')"
                                        outlined
                                        dense
                                        :items="minutes"
                                        v-model="minute"
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <!-- กำหนดรหัสใหม่ -->
                          <v-layout row wrap class="px-n4">
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_password"
                              :readonly="!password_share_link_status"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header class="pb-2">
                                  <v-flex xs3>
                                    <v-switch
                                      @change="
                                        checkbutton_password(
                                          password_share_link_status
                                        )
                                      "
                                      v-model="password_share_link_status"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    ></v-switch>
                                  </v-flex>
                                  <v-flex xs11>
                                    {{ $t("sharelinkfile.passwordheader") }}
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pb-0">
                                  <v-layout>
                                    <v-flex class="mt-3 ml-3 mb-4" xs10>
                                      <!-- กรอกรหัสผ่านลิงก์ -->
                                      <v-text-field
                                        autocomplete="off"
                                        :label="
                                          $t('checkpassword.inputpassword')
                                        "
                                        hide-details
                                        v-model="password_link"
                                        outlined
                                        dense
                                        @click:append="
                                          showpassword = !showpassword
                                        "
                                        prepend-icon="mdi-lock"
                                        :disabled="ownerpassword"
                                      ></v-text-field>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="pl-1 mt-3"
                                      v-if="pleasepasswordinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
              </div>
              <div v-else-if="filedata.file_permission_2 === '01'">
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkfile.cannotshare')"
                  hide-details
                  readonly
                ></v-switch>
              </div>
            </v-flex>
            <!-- Citizen -->
            <v-flex xs12 lg12 v-else-if="dataAccountActive.type === 'Citizen'">
              <!-- <v-switch
                v-if="switch1 === true"
                v-model="switch1"
                :color="color.theme"
                inset
                :label="$t('sharelinkfile.statusYes')"
                hide-details
                @change="check_share()"
                class="mb-4"
              >
              </v-switch>
              <v-switch
                v-if="switch1 === false"
                v-model="switch1"
                :color="color.theme"
                inset
                :label="$t('sharelinkfile.statusNo')"
                hide-details
                @change="check_share()"
                class="mb-4"
              ></v-switch> -->
              <span v-if="filedata.file_type === 'folder'" style="color: black; font-size: 14px">{{ $t("sharelinkfile.foldername") }}: {{ filedata.file_name }}</span>
              <span v-else style="color: black; font-size: 14px">{{ $t("sharelinkfile.filename") }}: {{ filedata.file_name }}</span>
              <br />
              <br />
              <span :style="titleText">{{
                $t("sharelinkfile.statusaccess")
              }}</span>
              <v-select
                v-model="typeshare"
                :items="itemsshare"
                dense
                outlined
                class="mt-1"
              >
              <!-- :disabled="!switch1" -->
                <template v-slot:item="{ item }">
                  <div class="text-center">
                    <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                    <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="text-center">
                    <span style="font-size: 16px;" v-if="item === 'จำกัด'">{{ $t('sharelinkfile.limit') }}</span>
                    <span style="font-size: 16px;" v-else>{{ $t('sharelinkfile.anyonewithalink') }}</span>
                  </div>
                </template>
              </v-select>
              <v-list-item class="mt-n2">
                <v-text-field
                  :color="color.theme"
                  v-model="link"
                  solo
                  name="input-7-4"
                  flat
                  label
                  outlined
                  rows="3"
                  dense
                  readonly
                  hide-details
                  class="mb-2 ml-n4"
                  style="width:20px;"
                  id="textfield"
                ></v-text-field>
                <!-- :disabled="!disable_link" -->
                <v-btn
                  class="mt-n2 mr-n4 ml-1"
                  :disabled="!disable_link"
                  color="green"
                  :dark="disable_link"
                  @click="copytextarea()"
                >
                  <v-icon>mdi-link mdi-rotate-135</v-icon>
                  {{ $t("sharelinkfile.copy") }}
                </v-btn>
              </v-list-item>
              <br />
              <v-layout row wrap>
                <!-- qr code -->
                <v-expansion-panels
                  v-model="switch2"
                  class="px-2 rounded-xl"
                  active-class="rounded-xl"
                  v-if="switch1 === true"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="my-n2 rounded-xl"
                    >
                      <span
                        ><v-icon small>mdi-qrcode-scan</v-icon
                        ><span :style="titleText" class="ml-2">{{
                          $t("sharelinkfile.showqrcode")
                        }}</span></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="py-2 rounded-xl" elevation="0">
                      <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                        <img
                          v-if="resolutionScreen >= 400"
                          id="img"
                          :src="'data:image/jpeg;base64,' + qr_code"
                          width="30%"
                        />
                        <img
                          v-else
                          id="img"
                          :src="'data:image/jpeg;base64,' + qr_code"
                          width="60%"
                        />
                      </v-flex>
                      <v-flex xs12 lg12 mx-3 d-flex justify-center class="mb-4">
                        <v-btn
                          class="mt-0"
                          :disabled="!disable_link"
                          :color="color.theme"
                          :dark="disable_link"
                          @click="saveqrcode()"
                          outlined
                        >
                          <v-icon small class="mr-1">mdi-qrcode-scan</v-icon
                          >{{ $t("sharelinkfile.saveqrcode") }}
                        </v-btn>
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <v-expansion-panels
                  class="mt-8 px-2 rounded-xl"
                  active-class="rounded-xl"
                >
                <!-- v-if="switch1 === true" -->
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      class="my-n2 rounded-xl"
                    >
                      <span
                        ><v-icon small>mdi-cogs</v-icon
                        ><span class="ml-2" :style="titleText">{{
                          $t("sharelinkfile.setsharelinkfile")
                        }}</span></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="py-2 rounded-xl" elevation="0">
                      <v-layout
                        v-if="name_share_eng !== 'System' && switch1 === true"
                        justify-end
                        row
                        wrap
                        class="mt-n6"
                      >
                        <v-flex xs12 class="text-right mt-3 mb-n1">
                          <p class="mb-0" v-if="$t('default') === 'th'">
                            {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                              name_share_th
                            }}
                          </p>
                          <p class="mb-0" v-else>
                            {{ $t("sharelinkfile.shareby") }}:&nbsp;{{
                              name_share_eng
                            }}
                          </p>
                        </v-flex>
                      </v-layout>
                      <!-- กรอก email -->
                      <div v-if="typeshare === 'จำกัด'">
                        <!-- switch1 === true && -->
                        <!-- ตั้งค่าการติดลายน้ำ -->
                        <v-layout row wrap class="px-n4">
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_watermark"
                            style="cursor: default"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header
                                class="pb-2"
                                align-start
                              >
                                <v-flex xs1>
                                  <v-switch
                                    v-model="status_watermark"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1 ml-n4"
                                  ></v-switch>
                                </v-flex>
                                <v-flex class="ml-6" xs11 :style="titleText">
                                  {{ $t("sharelinkfile.setwatermark") }} <br />
                                  <span xs12 style="font-size: 12px; line-height: 20px; color: red;">
                                    {{ $t("sharelinkfile.watermarlusefor") }}
                                  </span>
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <v-layout row wrap class="mx-4 mt-2">
                          <v-flex lg10 xs12 class="text-center">
                            <div
                              class="form-group"
                              v-for="(input, i) in inputs"
                              :key="i"
                            >
                              <v-layout row wrap class="mb-3">
                                <v-flex xs12 class="mb-n3">
                                  <v-text-field
                                    outlined
                                    dense
                                    type="email"
                                    v-model="input.email"
                                    :label="$t('email')"
                                    prepend-icon="mdi-email"
                                    clearable
                                    :rules="typeshare === 'จำกัด' ? emailErrors : ''"
                                    :error-messages="typeshare === 'จำกัด' ? input.erroremail : ''"
                                    @input="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                                    @blur="typeshare === 'จำกัด' ? $v.inputs.$each[i].email.$touch() : ''"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                  <v-select
                                    hide-details
                                    class="ml-8"
                                    :items="values_permis"
                                    :label="
                                      $t('sharelinkfile.permissionheader')
                                    "
                                    v-model="input.permission"
                                    outlined
                                    dense
                                    return-object
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  >
                                    <template v-slot:item="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                    <template v-slot:selection="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                  </v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap>
                                <v-flex lg12 class="text-left mt-n4 ml-n2">
                                  <v-card-title style="color:black; font-size: 14px;">
                                    {{ $t("sharelinkfile.timeheader") }}
                                  </v-card-title>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap>
                                <v-flex xs12 class="mb-n3">
                                  <v-dialog
                                    persistent
                                    v-model="input.modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        clearable
                                        v-bind="attrs"
                                        v-on="on"
                                        v-model="input.picker"
                                        :label="$t('sharelinkfile.date_set')"
                                        readonly
                                        prepend-icon="mdi-calendar"
                                        outlined
                                        dense
                                        @input="
                                          $v.inputs.$each[i].picker.$touch()
                                        "
                                        :error-messages="
                                          $v.inputs.$each[i].picker.$error
                                            ? 'โปรดระบุวันที่'
                                            : ''
                                        "
                                      >
                                      </v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="input.picker"
                                      :min="datenow"
                                      @input="input.modal = false"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click="input.modal = false"
                                      >
                                        {{ $t("confirm.closecomfirm") }}
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <v-flex xs12 class="mb-n3">
                                  <v-select
                                    :label="$t('sharelinkfile.hours_set')"
                                    outlined
                                    dense
                                    :items="hours"
                                    v-model="input.hour"
                                    prepend-icon="access_time"
                                    clearable
                                    @click:clear="
                                      $nextTick(() => (input.hour = ''))
                                    "
                                    @input="$v.inputs.$each[i].hour.$touch()"
                                    :error-messages="
                                      $v.inputs.$each[i].hour.$error
                                        ? 'โปรดระบุเวลา'
                                        : ''
                                    "
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex lg2 xs12 class="mb-n3">
                                  <v-select
                                    prepend-icon="access_time"
                                    :label="$t('sharelinkfile.minute_set')"
                                    outlined
                                    dense
                                    :items="minutes"
                                    v-model="input.minute"
                                    clearable
                                    @click:clear="
                                      $nextTick(() => (input.minute = ''))
                                    "
                                    @input="$v.inputs.$each[i].minute.$touch()"
                                    :error-messages="
                                      $v.inputs.$each[i].minute.$error
                                        ? 'โปรดระบุเวลา'
                                        : ''
                                    "
                                  >
                                  </v-select>
                                </v-flex>
                                <v-flex xs12 class="mb-4">
                                  <v-icon
                                    x-large
                                    @click="remove(i)"
                                    color="red"
                                    dark
                                    v-show="i || (!i && inputs.length > 1)"
                                    >mdi-minus-circle</v-icon
                                  >
                                  <v-icon
                                    x-large
                                    @click="add(i)"
                                    :color="color.theme"
                                    dark
                                    v-show="i == inputs.length - 1"
                                    >mdi-plus-circle</v-icon
                                  >
                                </v-flex>
                              </v-layout>
                            </div>
                          </v-flex>
                        </v-layout>
                      </div>
                      <div
                        v-if="
                          typeshare === 'ทุกคนที่มีลิงก์'
                        "
                      >
                      <!-- switch1 === true &&  -->
                        <!-- ตั้งค่าการติดลายน้ำ -->
                        <v-layout row wrap class="px-n4">
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_watermark"
                            style="cursor: default"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header
                                class="pb-2"
                                align-start
                              >
                                <v-flex xs3>
                                  <v-switch
                                    v-model="status_watermark"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  ></v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkfile.setwatermark") }} <br/>
                                  <span xs12 style="font-size: 12px; line-height: 20px; color: red;">
                                    {{ $t("sharelinkfile.watermarlusefor") }}
                                  </span>
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <!-- กำหนดจำนวนครั้งใหม่ -->
                        <v-layout row wrap class="px-n4">
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_number"
                            :readonly="!limit_status"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header
                                class="pb-2"
                                align-start
                              >
                                <v-flex xs3>
                                  <v-switch
                                    @change="
                                      checkbutton_number(
                                        limit_status,
                                        panel_number
                                      )
                                    "
                                    v-model="limit_status"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  ></v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkfile.limitheader") }}
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="pa-0 pb-2">
                                <v-layout row wrap>
                                  <v-flex xs9 class="ml-3">
                                    <v-text-field
                                      outlined
                                      dense
                                      class="ml-0 pl-3 mt-2 pr-2"
                                      v-model="access_limit"
                                      type="number"
                                      :hint="$t('sharelinkfile.limitset')"
                                      min="0"
                                      persistent-hint
                                      :disabled="!limit_status"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-2 pl-1"
                                    v-if="pleaselimitopeninput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                  <v-flex xs9 sm5 lg5 class="mt-5">
                                    <p style="color:red;" class="mb-0 pl-3">
                                      {{
                                        $t("sharelinkfile.numberopen_1")
                                      }}
                                      &nbsp;{{ access_limit_count }}/{{
                                        access_limit
                                      }}&nbsp;
                                      {{ $t("sharelinkfile.numberopen_2") }}
                                    </p>
                                  </v-flex>
                                  <v-flex class="pt-4 pl-2" xs2 lg1>
                                    <v-btn
                                      @mouseover="showReload = true"
                                      @mouseleave="showReload = false"
                                      v-if="limit_status === true"
                                      @click="resetshare()"
                                      color="green"
                                      dark
                                      absolute
                                      small
                                    >
                                      {{ $t("sharelinkfile.reset") }}
                                      <v-badge
                                        :value="showReload"
                                        :content="$t('sharelinkfile.resetword')"
                                        right
                                        transition="slide-y-transition"
                                      ></v-badge>
                                    </v-btn>
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <!-- กำหนดสิทธิ์ใหม่ -->
                        <v-layout row wrap class="px-n4">
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_permission"
                            :readonly="!permission_status"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pb-2">
                                <v-flex xs3>
                                  <v-switch
                                    @change="
                                      checkbutton_permission(permission_status)
                                    "
                                    v-model="permission_status"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  >
                                  </v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkfile.permissionheader") }}
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="pb-0">
                                <v-flex class="pt-3 ml-3" xs9>
                                  <v-select
                                    v-if="
                                      this.filedata['file_type'] === 'folder'
                                    "
                                    hide-details
                                    :items="
                                      filedata.file_permission_2 === '01'
                                        ? values_folder_viewonly
                                        : values_folder
                                    "
                                    v-model="permission_value"
                                    outlined
                                    dense
                                    return-object
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  >
                                    <template v-slot:item="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                    <template v-slot:selection="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                  </v-select>
                                  <v-select
                                    v-else
                                    hide-details
                                    :items="
                                      filedata.file_permission_2 === '01'
                                        ? values_file_viewonly
                                        : values_file
                                    "
                                    v-model="permission_value"
                                    outlined
                                    dense
                                    return-object
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  >
                                    <template v-slot:item="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                    <template v-slot:selection="{ item }">{{
                                      $t(item.text)
                                    }}</template>
                                  </v-select>
                                </v-flex>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <!-- กำหนดเวลาใหม่ -->
                        <v-layout row wrap>
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_time"
                            :readonly="!time_share_link_status"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pb-2">
                                <v-flex xs3 class="px-n4">
                                  <v-switch
                                    @change="
                                      checkbutton_time(time_share_link_status)
                                    "
                                    v-model="time_share_link_status"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  >
                                  </v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkfile.timeheader") }}
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="pb-0">
                                <v-layout row wrap>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-dialog
                                      persistent
                                      v-model="modal2"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          clearable
                                          v-bind="attrs"
                                          v-on="on"
                                          v-model="picker"
                                          :label="$t('sharelinkfile.date_set')"
                                          readonly
                                          prepend-icon="mdi-calendar"
                                          outlined
                                          dense
                                        >
                                        </v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="picker"
                                        :min="datenow"
                                        @input="modal2 = false"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click="modal2 = false"
                                        >
                                          {{ $t("confirm.closecomfirm") }}
                                        </v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-select
                                      prepend-icon="access_time"
                                      :label="$t('sharelinkfile.hours_set')"
                                      outlined
                                      dense
                                      :items="hours"
                                      v-model="hour"
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-select
                                      prepend-icon="access_time"
                                      :label="$t('sharelinkfile.minute_set')"
                                      outlined
                                      dense
                                      :items="minutes"
                                      v-model="minute"
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <!-- กำหนดรหัสใหม่ -->
                        <v-layout row wrap class="px-n4">
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_password"
                            :readonly="!password_share_link_status"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pb-2">
                                <v-flex xs3>
                                  <v-switch
                                    @change="
                                      checkbutton_password(
                                        password_share_link_status
                                      )
                                    "
                                    v-model="password_share_link_status"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  ></v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkfile.passwordheader") }}
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="pb-0">
                                <v-layout>
                                  <v-flex class="mt-3 ml-3 mb-4" xs10>
                                    <!-- กรอกรหัสผ่านลิงก์ -->
                                    <v-text-field
                                      autocomplete="off"
                                      :label="$t('checkpassword.inputpassword')"
                                      hide-details
                                      v-model="password_link"
                                      outlined
                                      dense
                                      @click:append="
                                        showpassword = !showpassword
                                      "
                                      prepend-icon="mdi-lock"
                                      :disabled="ownerpassword"
                                    ></v-text-field>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="pl-1 mt-3"
                                    v-if="pleasepasswordinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            v-if="switch1 === false"
            :loading="btnsetload"
            class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="60%"
            :color="color.theme"
            :disabled="typeshare === 'จำกัด' ? checkemail : false || loading"
            @click="opensharelink()"
            >{{ $t("sharelinkfile.statusYes") }}</v-btn
          >
          <v-btn
            v-else
            class="elevation-0 white--text mr-3"
            :style="btnAction"
            height="40px"
            width="50%"
            :color="color.theme"
            :disabled="loading"
            @click="closesharelink()"
            >{{ $t("sharelinkfile.statusNo") }}</v-btn
          >
          <v-btn
            v-if="switch1 === true"
            class="elevation-0 white--text"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            @click="updatesharelink()"
            :disabled="typeshare === 'จำกัด' ? checkemail : false || loading"
            >{{ $t("sharelinkfile.setting") }}</v-btn
          >
          <br><br>
          <v-btn
            :style="btnAction"
            class=""
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="$emit('close'), cleardata(), $emit('openDrag'), $emit('opendragselect'), checkreload === true ? $emit('reload') : ''"
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { requiredIf } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import format from "date-fns/format";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    // foldername: { required },
    inputs: {
      $each: {
        email:{ },
        picker: {
          requiredIf: requiredIf(function(input) {
            return (
              input.picker === null &&
              (input.hour !== "" || input.minute !== "")
            );
          }),
        },
        hour: {
          requiredIf: requiredIf(function(input) {
            return (
              input.hour === "" &&
              (input.picker !== null || input.minute !== "")
            );
          }),
        },
        minute: {
          requiredIF: requiredIf(function(input) {
            return (
              input.minute === "" &&
              (input.picker !== null || input.hour !== "")
            );
          }),
        },
      },
    },
  },
  name: "onebox_dialogSharefile",
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      listemailalert: [],
      inputs: [
        {
          email: "",
          erroremail: "",
          permission: { name: "view", key: "01", text: "sharefile.view" },
          modal: "",
          hour: "",
          minute: "",
          picker: null,
        },
      ],
      typeshare: "ทุกคนที่มีลิงก์",
      itemsshare: ["ทุกคนที่มีลิงก์", "จำกัด"],
      status_error_conut: 0,
      panel_permission: [1],
      panel_number: [1],
      panel_time: [1],
      panel_password: [1],
      panel_watermark: [0],
      ownerpassword: false,
      showpassword: false,
      password_link: "",
      password_share_link_status: false,
      datetimecheck: "",
      dateymd: "",
      datehour: "",
      dateminute: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      minute: "",
      hour: "",
      picker: "",
      datenow: "",
      modal2: false,
      modal: false,
      time_share_link_status: false,
      permission_value: "",
      values_permis: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_file: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_file_viewonly: [
        { name: "view", key: "01", text: "sharefile.view" },
        // { name: "download", key: "02", text: "sharefile.download" },
      ],
      values_folder: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_folder_viewonly: [
        { name: "view", key: "01", text: "sharefile.view" },
        // { name: "download", key: "02", text: "sharefile.download" },
      ],
      btnsetload: false,
      pleaselimitopeninput: false,
      pleasetimeinput: false,
      pleasepasswordinput: false,
      showReload: false,
      link: "",
      loading: true,
      switch1: false,
      switch2: false,
      foldername: "",
      createprogress: false,
      disable_link: false,
      status_share: "",
      limit_status: false,
      permission_status: false,
      access_limit_count: 0,
      access_limit: 0,
      name_share_th: "",
      name_share_eng: "",
      qr_code: "",
      status_watermark: false,
      checkreload: false,
      checkopenshare: false,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if(this.resolutionScreen >= 400) {
        return 600
      } else {
        return 346
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          //this.datenow = format(new Date(), "yyyy-MM-dd");
          // console.log("picker",this.picker);
          // if (this.filedata["file_type"] === "folder") {
          //   this.sharefolder(status);
          //this.picker="";
          // if (this.filedata["file_status_sharelink"] === "Y") {
          //   this.switch1 = true;
          //   this.link = process.env.VUE_APP_UI + "/publicfolder?id=" + this.filedata["file_linkshare"];
          //   // this.link = "http://localhost:8080/publicfolder?id=" + this.filedata['file_linkshare']
          //   this.loading = false;
          // } else {
          //   this.switch1 = false;
          //   this.loading = false;
          //   this.link = "";
          // }
          // } else {
          //   this.sharefile(status);
          //this.picker="";
          // if (this.filedata["file_status_sharelink"] === "Y") {
          //   this.switch1 = true;
          //   this.link = process.env.VUE_APP_UI + "/publicfile?id=" + this.filedata["file_linkshare"];
          //   // this.link = "http://localhost:8080/publicfile?id=" + this.filedata['file_linkshare']
          //   this.loading = false;
          // } else {
          //   this.switch1 = false;
          //   this.loading = false;
          //   this.link = "";
          // }
          // }
          // this.$emit("closeDrag");
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
    emailErrors() {
      console.log("this.typeshare", this.typeshare);
      if (this.typeshare === "จำกัด") {
        for (let i = 0; i < this.inputs.length; i++) {
          if (!this.$v.inputs.$each[i].email.$dirty) {
          } else if (this.inputs[i].email === "" || this.inputs[i].email === null) {
            this.inputs[i].erroremail = this.$t("sharelinkfile.pleasefillemail");
          } else {
            console.log("this.inputs[i].email.match(pattern)", this.inputs[i].email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/));
            if (this.inputs[i].email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null) {
              this.inputs[i].erroremail = this.$t("sharelinkfile.emailformatinvalid");
            } else {
              this.inputs[i].erroremail = "";
            }
          }
        }
      }
    },
    checkemail() {
      if (this.typeshare === "จำกัด") {
        let checkmail;
        for (let i = 0; i < this.inputs.length; i++) {
          if (!this.$v.inputs.$each[i].email.$dirty) {
            checkmail = true;
            break;
          } else if (this.inputs[i].email === "" || this.inputs[i].email === null) {
            checkmail = true;
            break;
          } else {
            // let pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            if (this.inputs[i].email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) == null) {
              checkmail = true;
              break;
            } else {
              console.log("true");
              checkmail = false;
            }
          }
        }
        return checkmail;
      } else {
        return false;
      }
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.datenow = format(new Date(), "yyyy-MM-dd");
        // console.log("picker",this.picker);
        this.checkreload = false;
        if (this.filedata["file_type"] === "folder") {
          this.sharefolder();
          //this.picker="";
          // if (this.filedata["file_status_sharelink"] === "Y") {
          //   this.switch1 = true;
          //   this.link = process.env.VUE_APP_UI + "/publicfolder?id=" + this.filedata["file_linkshare"];
          //   // this.link = "http://localhost:8080/publicfolder?id=" + this.filedata['file_linkshare']
          //   this.loading = false;
          // } else {
          //   this.switch1 = false;
          //   this.loading = false;
          //   this.link = "";
          // }
        } else {
          this.sharefile(status);
          //this.picker="";
          // if (this.filedata["file_status_sharelink"] === "Y") {
          //   this.switch1 = true;
          //   this.link = process.env.VUE_APP_UI + "/publicfile?id=" + this.filedata["file_linkshare"];
          //   // this.link = "http://localhost:8080/publicfile?id=" + this.filedata['file_linkshare']
          //   this.loading = false;
          // } else {
          //   this.switch1 = false;
          //   this.loading = false;
          //   this.link = "";
          // }
        }
        this.$emit("closeDrag");
      } else {
        this.$emit("openDrag");
      }
    },
  },
  filters: {
    subStr: function(val) {
      if (val.length > 22) return val.substring(0, 22) + "...";
      else return val;
    },
  },
  methods: {
    add(index) {
      this.inputs.push({
        email: "",
        permission: { name: "view", key: "01", text: "sharefile.view" },
        modal: "",
        hour: "",
        minute: "",
        picker: null,
      });
    },
    remove(index) {
      this.inputs.splice(index, 1);
    },
    checkbutton_number(_swicth) {
      console.log(_swicth);

      this.panel_number = [1];
      console.log(this.panel_number);
    },
    checkbutton_permission(_swicth) {
      console.log(_swicth);

      this.panel_permission = [1];
      console.log(this.panel_number);
    },
    checkbutton_time(_swicth) {
      console.log(_swicth);

      this.panel_time = [1];
      // console.log(this.panel_number);
    },
    checkbutton_password(_swicth) {
      console.log(_swicth);

      this.panel_password = [1];
      //  console.log(this.panel_number);
    },
    check_share() {
      if (this.switch1 === false) {
        this.limit_status = false;
        this.password_share_link_status = false;
        this.time_share_link_status = false;
        this.permission_status = false;
        this.panel_permission = [1];
        this.panel_number = [1];
        this.panel_time = [1];
        this.panel_password = [1];
      }
    },
    copytextarea() {
      let input = document.getElementById("textfield");
      input.select();
      document.execCommand("copy");
      Toast.fire({
        icon: "success",
        title: this.$t("sharelinkfile.copylinksucces"),
      });
    },
    async saveqrcode() {
      if (this.resolutionScreen > 500) {
        try {
          const handle = await window.showSaveFilePicker({
            suggestedName: "QR-Code Share link file",
            types: [
              {
                description: "Images",
                accept: {
                  "image/jpeg": ".jpeg",
                },
              },
            ],
            excludeAcceptAllOption: true,
          });
          const writable = await handle.createWritable();
          let input = document.getElementById("img").src;
          let response = await fetch(`${input}`);
          let imgblob = await response.blob();
          let blob = new Blob([imgblob], { type: imgblob.type });
          await writable.write(blob);
          await writable.close();
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = handle.name;
          link.click();

          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkfile.saveqrsucces"),
          });
          return handle;
        } catch (err) {
          console.error(err.name, err.message);
        }
      } else {
        let input = document.getElementById("img").src;
        let response = await fetch(`${input}`);
        let imgblob = await response.blob();
        let blob = new Blob([imgblob], { type: imgblob.type });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "QR-Code Share link file";
        link.click();
      }
    },
    updatestatusshare(status) {
      this.loading = true;
      if (this.filedata["file_type"] === "folder") {
        this.sharefolder();
        this.status_share = status;
      } else {
        //this.sharefile(status);
        this.set_sharefile(status);
      }
    },
    checkdatetime() {
      console.log(this.picker, this.hour, this.minute);
      if (this.picker === "" || this.picker == null) {
        this.dateymd = "00000000";
      } else {
        let dateyear =
          this.picker.split("")[0] +
          this.picker.split("")[1] +
          this.picker.split("")[2] +
          this.picker.split("")[3];

        let datemount = this.picker.split("")[5] + this.picker.split("")[6];
        let dateday = this.picker.split("")[8] + this.picker.split("")[9];
        this.dateymd = dateyear + datemount + dateday;
      }
      if (this.hour !== "") {
        this.datehour = this.hour.split("")[0] + this.hour.split("")[1];
      } else {
        this.datehour = "00";
      }
      if (this.minute !== "") {
        this.dateminute = this.minute.split("")[0] + this.minute.split("")[1];
      } else {
        this.dateminute = "00";
      }
      let datesecond = "00";

      this.datetimecheck =
        this.dateymd + this.datehour + this.dateminute + datesecond;
      console.log("datetimecheck", this.datetimecheck);
    },
    async opensharelink() {
      this.btnsetload = true;
      this.checkdatetime();
      let payload = {
        data_id: this.filedata["file_id"],
        data_type: this.filedata["file_type"] === "folder" ? "folder" : "file",
        account_id: this.dataAccountId,
        status: "Y",
        time_share_link: this.datetimecheck || "",
        time_share_link_status: this.time_share_link_status === true ? "Y" : "N",
        permission: this.permission_value["key"] || "",
        permission_status: this.typeshare === "ทุกคนที่มีลิงก์" ? this.permission_status === true ? "Y" : "N" : "Y",
        password_status: this.typeshare === "ทุกคนที่มีลิงก์" ? this.password_share_link_status === true ? "Y" : "N" : "N",
        password: this.typeshare === "ทุกคนที่มีลิงก์" ? this.password_link || "" : "",
        access_limit: this.typeshare === "ทุกคนที่มีลิงก์" ? this.access_limit || "" : "",
        access_limit_status: this.typeshare === "ทุกคนที่มีลิงก์" ? this.limit_status === true ? "Y" : "N" : "N",
        private_share: this.typeshare === "ทุกคนที่มีลิงก์" ? false : true,
        email_shared: this.typeshare === "ทุกคนที่มีลิงก์" ? [] : this.listemailalert,
        status_watermark: this.status_watermark === true ? "Y" : "N",
      };
      await this.fn_sharelink(payload, "open");
    },
    async closesharelink() {
      this.btnsetload = true;
      let payload = {
        data_id: this.filedata["file_id"],
        data_type: this.filedata["file_type"] === "folder" ? "folder" : "file",
        account_id: this.dataAccountId,
        status: "N",
        time_share_link: this.datetimecheck || "",
        time_share_link_status: "N",
        permission: this.permission_value["key"] || "",
        permission_status: "N",
        password_status: "N",
        password: this.password_link || "",
        access_limit: this.access_limit || "",
        access_limit_status: "N",
        private_share: false,
        email_shared: [],
        status_watermark: this.status_watermark === true ? "Y" : "N",
      };
      await this.fn_sharelink(payload, "closed");
    },
    async fn_sharelink(payload, status) {
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_data_link",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        //console.log(response);
        if (response.data.status === "OK") {
          console.log(this.switch1);
          if (status === "closed") {
            this.limit_status = false;
            this.password_share_link_status = false;
            this.time_share_link_status = false;
            this.permission_status = false;
            this.panel_permission = [1];
            this.panel_number = [1];
            this.panel_time = [1];
            this.panel_password = [1];
            this.disable_link = false;
            this.switch1 = false;
            this.status_watermark  = false;
            Toast.fire({
              icon: "success",
              title: this.$t("sharelinkfile.closesharelinkfilesucces"),
            });
          } else {
            this.switch1 = true;
            this.disable_link = true;
            this.copytextarea();
            this.name_share_th = response.data.result["share_by_th"];
            this.name_share_eng = response.data.result["share_by_eng"];
            Toast.fire({
              icon: "success",
              title: this.$t("sharelinkfile.opensharelinkfilesucces"),
            });
          }
          this.btnsetload = false;
          this.checkreload = true;
        } else if (response.data.errorCode === "ER403") {
          if (this.limit_status == true && this.access_limit == 0) {
            this.status_error_conut += 1;
            this.pleaselimitopeninput = true;
          } else {
            this.pleaselimitopeninput = false;
          }

          if (
            this.time_share_link_status === true &&
            this.dateymd == "00000000"
          ) {
            this.status_error_conut += 1;
            this.pleasetimeinput = true;
          } else {
            this.pleasetimeinput = false;
          }

          if (
            (this.password_share_link_status === true &&
              this.password_link === "") ||
            (this.password_share_link_status === true &&
              this.password_link.length < 6)
          ) {
            this.status_error_conut += 1;
            this.pleasepasswordinput = true;
          } else {
            this.pleasepasswordinput = false;
          }

          if (this.status_error_conut > 1) {
            Toast.fire({
              icon: "error",
              title: this.$t("sharelinkfile.sharelinkfilemanyerror"),
            });
          } else {
            if (this.limit_status == true && this.access_limit == 0) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.emptylimitopenfile"),
              });
            } else if (
              this.time_share_link_status === true &&
              this.dateymd == "00000000"
            ) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.choosedate"),
              });
            } else if (this.password_share_link_status === true) {
              if (this.password_link === "") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.emptypassword"),
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.passwordleast"),
                });
              }
            }
          }
          this.loading = false;
          this.btnsetload = false;
        } else {
          this.loading = false;
          this.btnsetload = false;
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkfile.sharelinkfileerror"),
            // title: response.data.errorCode + ":" + response.data.errorMessage,
          });
                  // this.inputs = [
        //   {
        //     email: "",
        //     permission: { name: "view", key: "01", text: "sharefile.view" },
        //     modal: "",
        //     hour: "",
        //     minute: "",
        //     picker: null,
        //   },
        // ];
        }

        this.status_error_conut = 0;
        this.btnsetload = false;
        this.loading = false;
      } catch (ex) {
        this.inputs = [
          {
            email: "",
            erroremail: "",
            permission: { name: "view", key: "01", text: "sharefile.view" },
            modal: "",
            hour: "",
            minute: "",
            picker: null,
          },
        ];
        this.btnsetload = false;
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkfile.sharelinkfileerror"),
        });
      }
    },
    updatesharelink() {
      // console.log('inputs',this.inputs);
      this.checkreload = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        if (this.filedata["file_type"] === "folder") {
          //this.sharefolder(status);
          this.set_sharefolder();
          //console.log(this.limit_openlink);
        } else {
          //this.sharefile(status);
          this.set_sharefile();
        }
      }
    },
    async set_sharefolder() {
      this.checkdatetime();
      this.btnsetload = true;
      let switch1 = this.switch1;
      let access_limit_status = this.limit_status;
      let permission_status = this.permission_status;
      let password_share_link_status = this.password_share_link_status;
      let time_share_link_status = this.time_share_link_status;

      if (switch1 === false) {
        time_share_link_status = false;
        password_share_link_status = false;
        access_limit_status = false;
      }
      let payload;
      if (this.typeshare === "ทุกคนที่มีลิงก์") {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "folder",
          account_id: this.dataAccountId,
          status: switch1 === true ? (switch1 = "Y") : (switch1 = "N"),
          time_share_link: this.datetimecheck || "",
          time_share_link_status:
            time_share_link_status === true
              ? (time_share_link_status = "Y")
              : (time_share_link_status = "N"),
          permission: this.permission_value["key"] || "",
          permission_status:
            permission_status === true
              ? (permission_status = "Y")
              : (permission_status = "N"),
          password_status:
            password_share_link_status === true
              ? (password_share_link_status = "Y")
              : (password_share_link_status = "N"),
          password: this.password_link || "",
          access_limit: this.access_limit || "",
          access_limit_status:
            access_limit_status === true
              ? (access_limit_status = "Y")
              : (access_limit_status = "N"),
          private_share: false,
          email_shared: [],
          status_watermark: this.status_watermark === true ? "Y" : "N",
        };
      } else {
        console.log(this.inputs);
        this.listemailalert = [];
        for (let i = 0; i < this.inputs.length; i++) {
          this.listemailalert.push({
            email: this.inputs[i].email,
            permission: this.inputs[i].permission.key || "00",
            time_share_link: this.fn_time_shareperpeople(
              this.inputs[i].picker,
              this.inputs[i].hour,
              this.inputs[i].minute
            ),
          });
        }
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "folder",
          account_id: this.dataAccountId,
          status: switch1 === true ? (switch1 = "Y") : (switch1 = "N"),
          time_share_link: this.datetimecheck || "",
          time_share_link_status:
            time_share_link_status === true
              ? (time_share_link_status = "Y")
              : (time_share_link_status = "N"),
          permission: "",
          permission_status: "N",
          password_status:
            password_share_link_status === true
              ? (password_share_link_status = "Y")
              : (password_share_link_status = "N"),
          password: this.password_link || "",
          access_limit: "",
          access_limit_status: "N",
          private_share: true,
          email_shared: this.listemailalert,
          status_watermark: this.status_watermark === true ? "Y" : "N",
        };
      }
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_data_link",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        console.log(this.password_link, this.password_link.length);
        //console.log(response);
        if (response.data.status === "OK") {
          console.log(this.switch1);
          this.disable_link = this.switch1;
          if (this.switch1 === false) {
            this.limit_status = this.switch1;
          }
          this.pleaselimitopeninput = false;
          this.pleasepasswordinput = false;
          this.pleasetimeinput = false;
          this.btnsetload = false;
          this.copytextarea();
          this.name_share_th = response.data.result["share_by_th"];
          this.name_share_eng = response.data.result["share_by_eng"];
          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkfile.settingsharelinkfilesucces"),
          });
        } else if (response.data.errorCode === "ER403") {
          if (this.limit_status == true && this.access_limit == 0) {
            this.status_error_conut += 1;
            this.pleaselimitopeninput = true;
          } else {
            this.pleaselimitopeninput = false;
          }

          if (
            this.time_share_link_status === true &&
            this.dateymd == "00000000"
          ) {
            this.status_error_conut += 1;
            this.pleasetimeinput = true;
          } else {
            this.pleasetimeinput = false;
          }

          if (
            (this.password_share_link_status === true &&
              this.password_link === "") ||
            (this.password_share_link_status === true &&
              this.password_link.length < 6)
          ) {
            this.status_error_conut += 1;
            this.pleasepasswordinput = true;
          } else {
            this.pleasepasswordinput = false;
          }

          if (this.status_error_conut > 1) {
            Toast.fire({
              icon: "error",
              title: this.$t("sharelinkfile.sharelinkfilemanyerror"),
            });
          } else {
            if (this.limit_status == true && this.access_limit == 0) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.emptylimitopenfile"),
              });
            } else if (
              this.time_share_link_status === true &&
              this.dateymd == "00000000"
            ) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.choosedate"),
              });
            } else if (this.password_share_link_status === true) {
              if (this.password_link === "") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.emptypassword"),
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.passwordleast"),
                });
              }
            }
          }
          this.loading = false;
          this.btnsetload = false;
        } else {
          this.loading = false;
          this.btnsetload = false;
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkfile.sharelinkfileerror"),
            // title: response.data.errorCode + ":" + response.data.errorMessage,
          });
                  // this.inputs = [
        //   {
        //     email: "",
        //     permission: { name: "view", key: "01", text: "sharefile.view" },
        //     modal: "",
        //     hour: "",
        //     minute: "",
        //     picker: null,
        //   },
        // ];
        }

        this.status_error_conut = 0;
        this.btnsetload = false;
        this.loading = false;
      } catch (ex) {
        this.inputs = [
          {
            email: "",
            erroremail: "",
            permission: { name: "view", key: "01", text: "sharefile.view" },
            modal: "",
            hour: "",
            minute: "",
            picker: null,
          },
        ];
        this.btnsetload = false;
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkfile.sharelinkfileerror"),
        });
      }
    },
    fn_time_shareperpeople(time, hour, minute) {
      console.log(time);
      console.log(hour);
      console.log(minute);
      let timeshare_link_perpeople = "";
      if (time !== undefined && time !== null && time !== "") {
        let timeshare_ = time.split("-");
        timeshare_link_perpeople =
          timeshare_[0] + timeshare_[1] + timeshare_[2] + hour + minute;
        console.log("timeshare_", timeshare_);
      }
      console.log("timeshare_link_perpeople", timeshare_link_perpeople);
      return timeshare_link_perpeople;
    },
    async set_sharefile() {
      this.checkdatetime();
      this.btnsetload = true;
      let switch1 = this.switch1;
      let access_limit_status = this.limit_status;
      let permission_status = this.permission_status;
      let time_share_link_status = this.time_share_link_status;
      let password_share_link_status = this.password_share_link_status;
      let payload;
      if (this.typeshare === "ทุกคนที่มีลิงก์") {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "file",
          account_id: this.dataAccountId,
          status: switch1 === true ? (switch1 = "Y") : (switch1 = "N"),
          time_share_link: this.datetimecheck || "",
          time_share_link_status:
            time_share_link_status === true
              ? (time_share_link_status = "Y")
              : (time_share_link_status = "N"),
          permission: this.permission_value["key"] || "",
          permission_status:
            permission_status === true
              ? (permission_status = "Y")
              : (permission_status = "N"),
          password_status:
            password_share_link_status === true
              ? (password_share_link_status = "Y")
              : (password_share_link_status = "N"),
          password: this.password_link || "",
          access_limit: this.access_limit || "",
          access_limit_status:
            access_limit_status === true
              ? (access_limit_status = "Y")
              : (access_limit_status = "N"),
          private_share: false,
          email_shared: [],
          status_watermark: this.status_watermark === true ? "Y" : "N",
        };
      } else {
        console.log(this.inputs);
        this.listemailalert = [];
        for (let i = 0; i < this.inputs.length; i++) {
          this.listemailalert.push({
            email: this.inputs[i].email,
            permission: this.inputs[i].permission.key || "00",
            time_share_link: this.fn_time_shareperpeople(
              this.inputs[i].picker,
              this.inputs[i].hour,
              this.inputs[i].minute
            ),
          });
        }
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "file",
          account_id: this.dataAccountId,
          status: switch1 === true ? (switch1 = "Y") : (switch1 = "N"),
          time_share_link: this.datetimecheck || "",
          time_share_link_status:
            time_share_link_status === true
              ? (time_share_link_status = "Y")
              : (time_share_link_status = "N"),
          permission: this.permission_value["key"] || "",
          permission_status:
            permission_status === true
              ? (permission_status = "Y")
              : (permission_status = "N"),
          password_status:
            password_share_link_status === true
              ? (password_share_link_status = "Y")
              : (password_share_link_status = "N"),
          password: this.password_link || "",
          access_limit: this.access_limit || "",
          access_limit_status:
            access_limit_status === true
              ? (access_limit_status = "Y")
              : (access_limit_status = "N"),
          private_share: true,
          email_shared: this.listemailalert,
          status_watermark: this.status_watermark === true ? "Y" : "N",
        };
      }
      //console.log("payload",payload);
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_data_link",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        //console.log(response);
        if (response.data.status === "OK") {
          this.disable_link = this.switch1;
          if (this.switch1 === false) {
            this.limit_status = this.switch1;
          }
          this.pleaselimitopeninput = false;
          this.pleasepasswordinput = false;
          this.pleasetimeinput = false;
          this.copytextarea();
          this.name_share_th = response.data.result["share_by_th"];
          this.name_share_eng = response.data.result["share_by_eng"];
          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkfile.settingsharelinkfilesucces"),
          });
        } else if (response.data.errorCode === "ER403") {
          if (this.limit_status == true && this.access_limit == 0) {
            this.status_error_conut += 1;
            this.pleaselimitopeninput = true;
          } else {
            this.pleaselimitopeninput = false;
          }

          if (
            this.time_share_link_status === true &&
            this.dateymd == "00000000"
          ) {
            this.status_error_conut += 1;
            this.pleasetimeinput = true;
          } else {
            this.pleasetimeinput = false;
          }
          if (
            (this.password_share_link_status === true &&
              this.password_link === "") ||
            (this.password_share_link_status === true &&
              this.password_link.length <= 6)
          ) {
            this.status_error_conut += 1;
            this.pleasepasswordinput = true;
          } else {
            this.pleasepasswordinput = false;
          }

          if (this.status_error_conut > 1) {
            Toast.fire({
              icon: "error",
              title: this.$t("sharelinkfile.sharelinkfilemanyerror"),
            });
          } else {
            if (this.limit_status == true && this.access_limit == 0) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.emptylimitopenfile"),
              });
            } else if (
              this.time_share_link_status === true &&
              this.dateymd == "00000000"
            ) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkfile.choosedate"),
              });
            } else if (this.password_share_link_status === true) {
              if (this.password_link === "") {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.emptypassword"),
                });
              } else {
                Toast.fire({
                  icon: "error",
                  title: this.$t("sharelinkfile.passwordleast"),
                });
              }
            }
          }
        } else {
          this.loading = false;
          this.btnsetload = false;
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkfile.sharelinkfileerror"),
            // title: response.data.errorCode + ":" + response.data.errorMessage,
          });
        }
        // this.inputs = [
        //   {
        //     email: "",
        //     permission: { name: "view", key: "01", text: "sharefile.view" },
        //     modal: "",
        //     hour: "",
        //     minute: "",
        //     picker: null,
        //   },
        // ];
        this.status_error_conut = 0;
        this.btnsetload = false;
        this.loading = false;
      } catch (ex) {
        this.inputs = [
          {
            email: "",
            erroremail: "",
            permission: { name: "view", key: "01", text: "sharefile.view" },
            modal: "",
            hour: "",
            minute: "",
            picker: null,
          },
        ];
        this.btnsetload = false;
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkfile.sharelinkfileerror"),
        });
      }
    },
    async sharefolder() {
      this.password_link = "";
      let payload = {
        data_id: this.filedata["file_id"],
        data_type: "folder",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        // process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_folder_link", payload, {
        process.env.VUE_APP_SERVICE_SHARE_FOLDER +
          "/api/share_link/generate_share_link",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false,
        }
      );

      try {
        if (response.data.status === "OK") {
          this.loading = false;
          console.log(response.data);
          //  if (status === "Y") {
          let status_share = response.data.result["status_share"];
          let status_share2 = response.data.result["status_share"];
          let permission_status = response.data.result["permission_status"];
          if (status_share === "Y") {
            this.switch1 = true;
          }
          this.permission_status =
            response.data.result["permission_status"] === "Y"
              ? (permission_status = true)
              : (permission_status = false);
          this.permission_value = this.checkPermission(
            response.data.result["permission"]
          );
          this.switch1 =
            status_share === "Y" || status_share === "P"
              ? (status_share = true)
              : (status_share = false);
          this.disable_link =
            status_share2 === "Y" || status_share2 === "P"
              ? (status_share2 = true)
              : (status_share2 = false);
          //console.log(this.switch1,this.disable_link);
          this.link =
            process.env.VUE_APP_UI +
            "/public?id=" +
            response.data.result["link"];
          // this.link = "http://localhost:8080/publicfolder?id=" + response.data.result['link']
          // } else {
          //   this.switch1 = false;
          //   this.link = "";
          this.name_share_th = response.data.result["name_shared_th"];
          this.name_share_eng = response.data.result["name_shared_eng"];
          this.access_limit_count = response.data.result["access_limit_count"];
          let access_limit_status = response.data.result["access_limit_status"];
          this.limit_status =
            access_limit_status === "Y"
              ? (access_limit_status = true)
              : (access_limit_status = false);
          this.access_limit = response.data.result["access_limit"];
          let password_status = response.data.result["password_status"];
          let time_share_link = response.data.result["time_share_link_status"];
          this.time_share_link_status =
            time_share_link === "Y"
              ? (this.time_share_link_status = true)
              : (this.time_share_link_status = false);
          this.password_share_link_status =
            password_status === "Y"
              ? (this.password_share_link_status = true)
              : (this.password_share_link_status = false);
          let date_year =
            response.data.result["time_share_link"].split("")[0] +
            response.data.result["time_share_link"].split("")[1] +
            response.data.result["time_share_link"].split("")[2] +
            response.data.result["time_share_link"].split("")[3];
          let date_mount =
            response.data.result["time_share_link"].split("")[4] +
            response.data.result["time_share_link"].split("")[5];
          let date_day =
            response.data.result["time_share_link"].split("")[6] +
            response.data.result["time_share_link"].split("")[7];
          if (response.data.result["time_share_link"] === "") {
            this.picker = "";
          } else {
            this.picker = date_year + "-" + date_mount + "-" + date_day;
          }
          console.log(date_year === NaN, this.picker);
          this.hour =
            response.data.result["time_share_link"].split("")[8] +
              response.data.result["time_share_link"].split("")[9] || "";
          this.minute =
            response.data.result["time_share_link"].split("")[10] +
              response.data.result["time_share_link"].split("")[11] || "";
          this.qr_code = response.data.result["qr_code"];
          this.typeshare = response.data.result["private_share"]
            ? "จำกัด"
            : "ทุกคนที่มีลิงก์";
          this.status_watermark =
            response.data.result["status_watermark"] === "Y"
              ? (this.status_watermark = true)
              : (this.status_watermark = false);
          console.log("response.data.result[status_watermark]", response.data.result["status_watermark"]);
          if (response.data.result["email_shared"].length === 0) {
            this.inputs = [
              {
                email: "",
                erroremail: "",
                permission: { name: "view", key: "01", text: "sharefile.view" },
                modal: "",
                hour: "",
                minute: "",
                picker: null,
              },
            ];
          } else {
            // this.inputs = [];
            for (
              let i = 0;
              i < response.data.result["email_shared"].length;
              i++
            ) {
              let datetime =
                response.data.result["email_shared"][i]["time_share_link"];
              this.inputs.push({
                email: response.data.result["email_shared"][i]["email"],
                permission: this.checkPermission(
                  response.data.result["email_shared"][i]["permission"]
                ),
                picker:
                  datetime === ""
                    ? null
                    : `${datetime.slice(0, 4)}-${datetime.slice(
                        4,
                        6
                      )}-${datetime.slice(6, 8)}`,
                hour: datetime.slice(8, 10),
                minute: datetime.slice(10, 12),
              });
            }
          }
          // }
        } else {
          this.loading = false;
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      } catch (ex) {
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkfile.sharelinkfileerror"),
        });
      }
    },
    async sharefile() {
      this.password_link = "";
      let payload = {
        data_id: this.filedata["file_id"],
        data_type: "file",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        // process.env.VUE_APP_SERVICE_SHARE_FILE + "/api/share_file_link", payload, {
        process.env.VUE_APP_SERVICE_SHARE_FOLDER +
          "/api/share_link/generate_share_link",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false,
        }
      );

      try {
        if (response.data.status === "OK") {
          this.loading = false;
          let status_share = response.data.result["status_share"];
          let status_share2 = response.data.result["status_share"];
          if (status_share === "Y") {
            this.switch1 = true;
          }
          this.disable_link =
            status_share2 === "Y" || status_share2 === "P"
              ? (status_share2 = true)
              : (status_share2 = false);
          //console.log(status_share);
          this.switch1 =
            status_share === "Y" || status_share === "P"
              ? (status_share = true)
              : (status_share = false);
          //console.log(this.switch1);
          let permission_status = response.data.result["permission_status"];
          this.permission_status =
            response.data.result["permission_status"] === "Y"
              ? (permission_status = true)
              : (permission_status = false);
          this.permission_value = this.checkPermission(
            response.data.result["permission"]
          );
          this.link =
            process.env.VUE_APP_UI +
            "/public?id=" +
            response.data.result["link"];
          this.name_share_th = response.data.result["name_shared_th"];
          this.name_share_eng = response.data.result["name_shared_eng"];
          this.access_limit_count = response.data.result["access_limit_count"];
          let access_limit_status = response.data.result["access_limit_status"];
          this.limit_status =
            access_limit_status === "Y"
              ? (access_limit_status = true)
              : (access_limit_status = false);
          this.access_limit = response.data.result["access_limit"];
          let time_share_link = response.data.result["time_share_link_status"];
          this.time_share_link_status =
            time_share_link === "Y"
              ? (this.time_share_link_status = true)
              : (this.time_share_link_status = false);
          let password_status = response.data.result["password_status"];
          this.password_share_link_status =
            password_status === "Y"
              ? (this.password_share_link_status = true)
              : (this.password_share_link_status = false);
          let date_year =
            response.data.result["time_share_link"].split("")[0] +
            response.data.result["time_share_link"].split("")[1] +
            response.data.result["time_share_link"].split("")[2] +
            response.data.result["time_share_link"].split("")[3];
          let date_mount =
            response.data.result["time_share_link"].split("")[4] +
            response.data.result["time_share_link"].split("")[5];
          let date_day =
            response.data.result["time_share_link"].split("")[6] +
            response.data.result["time_share_link"].split("")[7];
          if (response.data.result["time_share_link"] === "") {
            this.picker = "";
          } else {
            this.picker = date_year + "-" + date_mount + "-" + date_day;
          }
          console.log(date_year, this.picker);
          // this.picker = date_year + "-" + date_mount + "-" + date_day || "";
          this.hour =
            response.data.result["time_share_link"].split("")[8] +
              response.data.result["time_share_link"].split("")[9] || "";
          this.minute =
            response.data.result["time_share_link"].split("")[10] +
              response.data.result["time_share_link"].split("")[11] || "";
          this.qr_code = response.data.result["qr_code"];
          this.typeshare = response.data.result["private_share"]
            ? "จำกัด"
            : "ทุกคนที่มีลิงก์";
          this.status_watermark =
            response.data.result["status_watermark"] === "Y"
              ? (this.status_watermark = true)
              : (this.status_watermark = false);
          console.log("response.data.result[status_watermark]", response.data.result["status_watermark"]);
          if (response.data.result["email_shared"].length === 0) {
            this.inputs = [
              {
                email: "",
                erroremail: "",
                permission: { name: "view", key: "01", text: "sharefile.view" },
                modal: "",
                hour: "",
                minute: "",
                picker: null,
              },
            ];
          } else {
            // this.inputs = [];
            for (
              let i = 0;
              i < response.data.result["email_shared"].length;
              i++
            ) {
              let datetime =
                response.data.result["email_shared"][i]["time_share_link"];
              this.inputs.push({
                email: response.data.result["email_shared"][i]["email"],
                permission: this.checkPermission(
                  response.data.result["email_shared"][i]["permission"]
                ),
                picker:
                  datetime === ""
                    ? null
                    : `${datetime.slice(0, 4)}-${datetime.slice(
                        4,
                        6
                      )}-${datetime.slice(6, 8)}`,
                hour: datetime.slice(8, 10),
                minute: datetime.slice(10, 12),
              });
            }
          }
          // if (status === "Y") {
          //   this.switch1 = true;
          //   this.link = process.env.VUE_APP_UI + "/publicfile?id=" + response.data.result["link"];
          //   // this.link = "http://localhost:8080/publicfile?id=" + response.data.result['link']
          // } else {
          //   this.switch1 = false;
          //   this.link = "";
          // }
        } else {
          this.loading = false;
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkfile.sharelinkfileerror"),
          });
        }
      } catch (ex) {
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkfile.sharelinkfileerror"),
        });
      }
    },
    async resetshare() {
      let payload;
      if (this.filedata["file_type"] === "folder") {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "folder",
        };
      } else {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "file",
        };
      }
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        // process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_folder_link", payload, {
        process.env.VUE_APP_SERVICE_SHARE_FOLDER +
          "/api/share_link/reset_access_limit_count",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        if (response.data.status === "OK") {
          this.access_limit_count = response.data.access_limit_count;
        } else {
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkfile.sharelinkfileerror"),
          });
        }
      } catch {}
    },
    checkPermission(permission) {
      let permission_value = {};
      if (permission === "01") {
        permission_value = {
          name: "view",
          key: "01",
          text: "sharefile.view",
        };
      } else if (permission === "02") {
        permission_value = {
          name: "download",
          key: "02",
          text: "sharefile.download",
        };
      } else if (permission === "03") {
        permission_value = {
          name: "upload",
          key: "03",
          text: "sharefile.upload",
        };
      } else if (permission === "04") {
        permission_value = {
          name: "edit",
          key: "04",
          text: "sharefile.edit",
        };
      } else if (permission === "05") {
        permission_value = {
          name: "delete",
          key: "05",
          text: "sharefile.delete",
        };
      }
      return permission_value;
    },
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
      // this.switch1 = false;
      this.switch2 = false;
      this.access_limit = 0;
      this.limit_status === false;
      this.disable_link = false;
      this.pleasepasswordinput = false;
      this.pleaselimitopeninput = false;
      this.pleasetimeinput = false;
      this.permission_status = "";
      this.permission_value = "";
      this.time_share_link_status = false;
      this.password_share_link_status = false;
      this.password_link = "";
      this.panel_permission = [1];
      this.panel_number = [1];
      this.panel_time = [1];
      this.panel_password = [1];
      this.inputs = [];
      this.btnsetload = false;
      // this.checkopenshare = false;
    },
  },
  // mounted() {
  //   this.switch1 = true;
  // }
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>
